import React from "react"
import { Link } from "react-router"
import CarouselComponent from "../../reusable components/Carousel/CarouselComponent"
import { EditCMSWrapper } from "../../reusable components/EditCMS/EditCMSWrapper"
import AboutComponent from "./components/AboutComponent"
import ProjectComponent from "./components/ProjectComponent"
import QualityComponent from "./components/QualityComponent"
import ServiceComponent from "./components/ServiceComponent"
import TeamsComponent from "./components/TeamsComponent"

export const FrontPage = ({ webData }) => {
  const slides = [
    {
      image: webData["heroImage1"],
      title: "frontPage1",
      description: "frontPage2",
    },
    {
      image: webData["heroImage2"],
      title: "frontPage3",
      description: "frontPage4",
    },
    {
      image: webData["heroImage3"],
      title: "frontPage5",
      description: "frontPage6",
    },
  ]
  return (
    <>
      <CarouselComponent slides={slides} height={600} />

      {ConnectSection()}
      <ProjectComponent />
      <ServiceComponent />

      <QualityComponent />
      <AboutComponent />
      <TeamsComponent />

      {/* <section id="blog_home">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="service_h1 text-center">
                <h2>
                  <EditCMSWrapper dataKey="hero1" />
                </h2>
                <span className="block_icon">
                  <i className="fa fa-leaf" />
                </span>
                <p>
                  <EditCMSWrapper dataKey="hero1" />
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="blog_home1 clearfix">
                <div className="col-sm-4">
                  <div className="blog_home1i clearfix">
                    <div className="grid clearfix">
                      <figure className="effect-jazz">
                        <Link to="/">
                          <img
                            src="/images/frontPageImages/23.jpg"
                            className="iw mgt"
                            alt="img25"
                          />
                        </Link>
                      </figure>
                    </div>
                    <div className="blog_home1ii clearfix">
                      <h4>
                        <Link to="/">
                          <EditCMSWrapper dataKey="hero1" />
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <i className="fa fa-user" /> By{" "}
                          <Link to="/"> Aptent</Link>
                        </li>
                        <li>
                          <i className="fa fa-comment" />{" "}
                          <Link to="/">0 comment</Link>
                        </li>
                      </ul>
                      <p>
                        Lorem Ipsum by the readable content of Link page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has Link <Link to="/">Read More</Link>
                      </p>
                    </div>
                    <div className="blog_home1il clearfix">
                      <h6 className="mgt">
                        <span className="date">10 AUG</span>{" "}
                        <span className="year">2019</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="blog_home1i clearfix">
                    <div className="grid clearfix">
                      <figure className="effect-jazz">
                        <Link to="/">
                          <img
                            src="/images/frontPageImages/24.jpg"
                            className="iw mgt"
                            alt="img25"
                          />
                        </Link>
                      </figure>
                    </div>
                    <div className="blog_home1ii clearfix">
                      <h4>
                        <Link to="/">
                          Gardening the Global Greenhouse by Richard Bisgrove..
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <i className="fa fa-user" /> By{" "}
                          <Link to="/"> Aptent </Link>
                        </li>
                        <li>
                          <i className="fa fa-comment" />{" "}
                          <Link to="/">0 comment</Link>
                        </li>
                      </ul>
                      <p>
                        Lorem Ipsum by the readable content of Link page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has Link <Link to="/">Read More</Link>
                      </p>
                    </div>
                    <div className="blog_home1il clearfix">
                      <h6 className="mgt">
                        <span className="date">10 AUG</span>{" "}
                        <span className="year">2019</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="blog_home1i clearfix">
                    <div className="grid clearfix">
                      <figure className="effect-jazz">
                        <Link to="/">
                          <img
                            src="/images/frontPageImages/25.jpg"
                            className="iw mgt"
                            alt="img25"
                          />
                        </Link>
                      </figure>
                    </div>
                    <div className="blog_home1ii clearfix">
                      <h4>
                        <Link to="/">
                          Gardening the Global Greenhouse by Richard Bisgrove..
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <i className="fa fa-user" /> By{" "}
                          <Link to="/"> Aptent</Link>
                        </li>
                        <li>
                          <i className="fa fa-comment" />{" "}
                          <Link to="/">0 comment</Link>
                        </li>
                      </ul>
                      <p>
                        Lorem Ipsum by the readable content of Link page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has Link <Link to="/">Read More</Link>
                      </p>
                    </div>
                    <div className="blog_home1il clearfix">
                      <h6 className="mgt">
                        <span className="date">10 AUG</span>{" "}
                        <span className="year">2019</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}
function ConnectSection() {
  return (
    <section id="connect">
      <div className="container">
        <div className="row">
          <div className="connect_1 clearfix">
            <div className="col-sm-10">
              <div className="connect_1l clearfix">
                <p className="mgt col">
                  <EditCMSWrapper dataKey="frontPage7" />
                </p>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="connect_1r clearfix">
                <h5 className="mgt">
                  <Link className="button mgt" to="/contact">
                    CONTACT
                  </Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="connect_2 clearfix">
            {/* Repeatable Blocks */}
            {["frontPage8", "frontPage10", "frontPage12"].map((key, index) => (
              <div className="col-sm-4" key={`connect_${index}`}>
                <div className="connect_2i clearfix">
                  <div className="col-sm-2 space_all">
                    <div className="connect_2il clearfix">
                      <span className="col_1">
                        <i
                          className={`fa ${
                            ["fa-tree", "fa-user", "fa-hand-lizard-o"][index]
                          }`}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="connect_2ir clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey={`${key}`} />
                      </h5>
                      <p>
                        <EditCMSWrapper dataKey={`${key}_desc`} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
