import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { translateCustom } from "../i18n/i18n"

const TextFieldComponent = ({
  headerName,
  field,
  value,
  onChange,
  pattern,
  errorMessage,
  disabled = false,
  required = false,
  maxLength,
  minLength,
  type,
}) => {
  const [error, setError] = useState(false)

  const handleBlur = () => {
    if (pattern) {
      const regex = new RegExp(pattern)
      setError(!regex.test(value || ""))
    }
  }

  return (
    <Box>
      <TextField
        fullWidth
        slotProps={{
          htmlInput: { pattern, maxLength, minLength },
        }}
        type={type}
        label={translateCustom(headerName)}
        name={field}
        value={value || ""}
        onChange={onChange}
        onBlur={handleBlur}
        error={error}
        helperText={error ? errorMessage : ""}
        disabled={disabled}
        required={required}
      />
    </Box>
  )
}

export default TextFieldComponent
