import React, { useEffect } from "react"
import { Route, Routes, UNSAFE_createBrowserHistory } from "react-router-dom"
import { fetchWebData, forgetPassword, handleLogin, resetPassword } from "./api/axiosFunctions"
import { AboutUs } from "./components/frontPage/AboutUs"
import { Contact } from "./components/frontPage/Contact"
import { FAQ } from "./components/frontPage/FAQ"
import { FrontPage } from "./components/frontPage/LandingPage"
import { FrontPageLayout } from "./components/frontPage/LandingPageLayout"
import { Project } from "./components/frontPage/Project"
import { Services } from "./components/frontPage/Services"
import { Login } from "./reusable components/Authentication/Login"
import RedirectIfAuthenticated from "./RedirectIfAuthenticated"

import DashboardInit from "./components/DashboardPages/DashboardInit"
import { validateToken } from "./api/validateToken"
import NotFoundPage from "./reusable components/NotFoundPage/NotFoundPage "
import { getDynamicContext, setContext } from "./context/AppContext"
import { ForgotPassword } from "./reusable components/Authentication/ForgotPassword"
import { ResetPassword } from "./reusable components/Authentication/ResetPassword"

// Doesn't rerender components!!!
const history = UNSAFE_createBrowserHistory()
export const navigateTo = (path) => history.push(path)

const App = () => {
  validateToken()
  const { webData } = getDynamicContext()

  useEffect(() => {
    ;(async () => {
      setContext({ webData: await fetchWebData() })
    })()
  }, [])

  return (
    <Routes>
      {/* Public Routes */}
      <Route element={<FrontPageLayout webData={webData} />}>
        <Route path="/" element={<FrontPage webData={webData} />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/project" element={<Project />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
      {/* Auth Route */}
      <Route
        path="/login"
        element={
          <RedirectIfAuthenticated>
            <Login
              siteName={"Agriculture"}
              loggingFunction={handleLogin}
              logo="/logo.png"
            />
          </RedirectIfAuthenticated>
        }
      />
      <Route
        path="/forgot-password"
        element={<ForgotPassword
        
          siteName={"Agriculture"}
          forgetPasswordFunction={forgetPassword}
          logo="/logo.png"
        />}
      />
      <Route
        path="/reset-password"
        element={<ResetPassword
        
          siteName={"Agriculture"}
          resetPasswordFunction={resetPassword}
          logo="/logo.png"
        />}
      />

      {DashboardInit()}
      {/* 404 Page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default App
