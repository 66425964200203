import React, { createContext, useContext, useState } from "react"

export const contextObject = {}

const initContext = {
  themeMode: "light",
  webData: {},
  loggedUserData: JSON.parse(localStorage.getItem("loggedUserData")),
  terrains: [],
  users: [],
  NPK_Data: [],
  luxData: [],
  HT_EC_Data: [],
  windData: [],
}
const AppContext = createContext(initContext)
export const AppProvider = ({ children }) => {
  const [state, setState] = useState(initContext)

  contextObject.state = state
  contextObject.setState = setState

  return (
    <AppContext.Provider value={{ ...state}}>{children}</AppContext.Provider>
  )
}

export const getDynamicContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("getContext must be used within an AppProvider")
  }
  return context
}

export const getContext = () => contextObject.state

export const setContext = (newValues) =>
  contextObject.setState((prev) => ({
    ...prev,
    ...newValues,
  }))

// const {loggedUserData } = getContext()
// const {loggedUserData } = getDynamicContext()

// setContext({})
