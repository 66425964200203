import React from "react"
import {  getDynamicContext, setContext } from "../../context/AppContext"
import { EditCMS } from "./EditCMS"

export const EditCMSWrapper = ({ dataKey, type, imageProps }) => {
  const { webData, loggedUserData } = getDynamicContext() // Access user data from AppContext
  const userRole = loggedUserData?.role || "" // Get user role, default to empty string if not found

  return (
    <EditCMS
      imageProps={imageProps || {}} // Default to an empty object
      dataKey={dataKey}
      webData={webData}
      type={type}
      userRole={userRole} // Pass user role to EditCMS
      setContext={setContext} // Pass user role to EditCMS
    />
  )
}
