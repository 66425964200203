import { Box } from "@mui/material"
import React from "react"
import RadialGauge from "../../reusable components/../reusable components/Meters/RadialGauge"
import GaugeComponent from "react-gauge-component"

import CardComponent from "../../reusable components/Card/CardComponent"
import ChartTemplate from "../Dashboard/components/ChartTemplate"
import { getDynamicContext } from "../../context/AppContext"
import {
  getHighestValuesByMonth,
  getLastRecordsFor30Days,
} from "../../api/data"

const columns = [
  {
    field: "intensity",
    headerName: "Light radiation",
    flex: 0.5,
    minWidth: 80,
  },
  {
    field: "value",
    headerName: "Value",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "result",
    headerName: "Result",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "timestamp",
    headerName: "Time",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
]

const lineChartSeries = [
  {
    id: "intensity",
    dataKey: "intensity",
    label: "Light radiation",
    showMark: false,
    curve: "linear",
    area: true,
    stackOrder: "ascending",
    color: "#52b202",
  },
]
const FullCircleRadialGauge = ({
  value = 50,
  max = 100,
  size = 200,
  strokeWidth = 15,
}) => {
  const normalizedValue = Math.min(Math.max(value, 0), max) // Clamp the value between 0 and max
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (normalizedValue / max) * circumference

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Background Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="#ddd"
        strokeWidth={strokeWidth}
        fill="none"
      />
      {/* Foreground Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="#4caf50"
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotate to start at the top
        strokeLinecap="round"
      />
      {/* Center Text */}
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="20px"
        fontWeight="bold"
        fill="#333"
      >
        {Math.round((normalizedValue / max) * 100)}%
      </text>
    </svg>
  )
}

const gradients = [{ id: "intensity", color: "#52b202" }]

const series = [{ dataKey: "intensity", label: "Light radiation" }]

const colorPalette = ["#52b202"]
function LightRadiationPage() {
  const { luxData } = getDynamicContext()

  if (!luxData.length) return <h1>No Data</h1>

  const lastRecord = luxData.at(-1)

  const lastRecordsFor30Days = getLastRecordsFor30Days(luxData)

  const curveData = [
    {
      title: "Light radiation",
      value: "14",
      interval: "Last 30 days",
      trend: "up",
      data: lastRecordsFor30Days.map((record) => record.intensity).slice(-30),
    },
  ]

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          flexWrap: "wrap",
          marginTop: 3,
        }}
      >
        {/* Light Radiation Card */}
        <CardComponent title="Light Radiation">
          {/* <DashboardLuminosity value={(lastRecord.Radiation * 100) / 1000} /> */}
          <GaugeComponent
            value={(lastRecord.intensity * 100) / 1000}
            type="radial"
            labels={{
              tickLabels: {
                defaultTickValueConfig: {
                  style: { fontSize: "10px", fill: "#000" },
                },
                type: "inner",
                ticks: [
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                ],
              },
              valueLabel: {
                style: {
                  fontSize: "24px",
                  fill: "#000",
                }, // Change the value color here
              },
            }}
            arc={{
              colorArray: ["#5BE12C", "#EA4228"],
              subArcs: [
                { limit: 10 },
                { limit: 30 },
                { limit: 50 },
                { limit: 70 },
                { limit: 90 },
              ],
              padding: 0.02,
              width: 0.3,
            }}
            pointer={{
              elastic: true,
              animationDelay: 0,
            }}
          />

          {/* <FullCircleRadialGauge /> */}
        </CardComponent>
        {/* Radial Gauge Card */}
        <CardComponent title={"Light Radiation"}>
          <RadialGauge value={lastRecord.intensity} />
        </CardComponent>
      </Box>
      <ChartTemplate
        chartTitle={"Light Radiation"}
        rows={luxData}
        columns={columns}
        curveData={curveData}
        series={series}
        colorPalette={colorPalette}
        lineChartSeries={lineChartSeries}
        gradients={gradients}
        pageViewBarChartDataset={getHighestValuesByMonth(luxData, [
          ["intensity"],
        ])}
      />
    </>
  )
}

export default LightRadiationPage
