import axios from "axios"
import { axiosInterceptorResponse } from "./axiosInterceptorResponse"
import { axiosInterceptorSend } from "./axiosInterceptorSend"

export const isProduction = process.env.NODE_ENV !== "development"

const { REACT_APP_DEVELOPMENT_SERVER, REACT_APP_PRODUCTION_SERVER } =
  process.env

const baseURL = isProduction
  ? REACT_APP_PRODUCTION_SERVER
  : REACT_APP_DEVELOPMENT_SERVER

export const axiosAPI = axios.create({ baseURL })

axiosInterceptorSend(axiosAPI)
axiosInterceptorResponse(axiosAPI)
