import { Box } from "@mui/material"
import { Chart } from "react-google-charts"

import CardComponent from "../../reusable components/Card/CardComponent"
import ChartTemplate from "../Dashboard/components/ChartTemplate"
import {
  getHighestValuesByMonth,
  getLastRecordsFor30Days,
} from "../../api/data"
import { getDynamicContext } from "../../context/AppContext"

const columns = [
  {
    field: "speed",
    headerName: "Wind Speed",
    flex: 0.5,
    minWidth: 80,
  },
  {
    field: "value",
    headerName: "Value",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "result",
    headerName: "Result",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "timestamp",
    headerName: "Time",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
]

const lineChartSeries = [
  {
    id: "speed",
    dataKey: "speed",
    label: "Wind speed",
    showMark: false,
    curve: "linear",
    area: true,
    stackOrder: "ascending",
    color: "#52b202",
  },
]

const gradients = [{ id: "speed", color: "#52b202" }]

const series = [{ dataKey: "speed", label: "Wind Speed" }]

const colorPalette = ["#52b202", "#d32f2f", "#f57c00"]

function WindSpeedPage() {
  const { windData } = getDynamicContext()
  const lastRecord = windData.at(-1)

  const lastRecordsFor30Days = getLastRecordsFor30Days(windData)

  const curveData = [
    {
      title: "Wind Speed",
      value: "14",
      interval: "Last 30 days",
      trend: "up",
      data: lastRecordsFor30Days.map((record) => record.speed).slice(-30),
    },
  ]

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          marginTop: 3,
        }}
      >
        <CardComponent
          cardType="windSpeed"
          title={`Wind Speed (${lastRecord.interpretation.speed})`}
        >
          <Chart
            height={250}
            chartType="Gauge"
            loader={<div>Loading Chart...</div>}
            data={[
              ["Label", "Value"],
              ["Wind Speed", lastRecord.speed],
            ]}
            options={{
              redFrom: 90,
              redTo: 200,
              yellowFrom: 50,
              yellowTo: 90,
              minorTicks: 5,
              min: 0,
              max: 50,
            }}
          />
        </CardComponent>
      </Box>
      <ChartTemplate
        chartTitle={"Wind Speed"}
        rows={windData}
        columns={columns}
        curveData={curveData}
        series={series}
        colorPalette={colorPalette}
        lineChartSeries={lineChartSeries}
        gradients={gradients}
        pageViewBarChartDataset={getHighestValuesByMonth(windData, [["speed"]])}
      />
    </>
  )
}

export default WindSpeedPage
