import * as React from "react"
import { styled } from "@mui/material/styles"
import Divider, { dividerClasses } from "@mui/material/Divider"
import Menu from "@mui/material/Menu"
import MuiMenuItem from "@mui/material/MenuItem"
import { paperClasses } from "@mui/material/Paper"
import { listClasses } from "@mui/material/List"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon, { listItemIconClasses } from "@mui/material/ListItemIcon"
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded"
import MenuButton from "./MenuButton"
import { dashboardContext } from "../Dashboard"
import { Link } from "react-router-dom"
import { translateCustom } from "../../../reusable components/i18n/i18n"

const MenuItem = styled(MuiMenuItem)({
  margin: "2px 0",
})

export default function OptionsMenu() {
  const { logoutFunction, optionsMenuItems } =
    React.useContext(dashboardContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (menuItem: (typeof optionsMenuItems)[number]) => {
    if (menuItem.onClick) {
      menuItem.onClick()
    }
    if (menuItem.label === "Logout") {
      logoutFunction()
    }
    handleClose()
  }

  return (
    <React.Fragment>
      <MenuButton
        aria-label="Open menu"
        onClick={handleClick}
        sx={{ borderColor: "transparent" }}
      >
        <MoreVertRoundedIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: "4px",
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: "4px -4px",
          },
        }}
      >
        {optionsMenuItems.map((menuItem, index) =>
          menuItem.label === "Divider" ? (
            <Divider key={`divider-${index}`} />
          ) : (
            <MenuItem
              key={menuItem.label}
              onClick={() => handleMenuItemClick(menuItem)}
              sx={{
                [`& .${listItemIconClasses.root}`]: {
                  ml: "auto",
                  minWidth: 0,
                },
              }}
            >
              <Link to={menuItem.link || ""}>
                <ListItemText>{translateCustom(menuItem.label)}</ListItemText>
              </Link>
              {menuItem.Icon && <ListItemIcon>{menuItem.Icon}</ListItemIcon>}
            </MenuItem>
          )
        )}
      </Menu>
    </React.Fragment>
  )
}
