import React from "react"
import ReactSpeedometer from "react-d3-speedometer"
import CardComponent from "../Card/CardComponent"

const ReactSpeedometerComponent = ( props) => {
  return (
    <CardComponent title={props.title}>
      <ReactSpeedometer {...props} />
    </CardComponent>
  )
}

export default ReactSpeedometerComponent
