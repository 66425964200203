import React from "react"
import { EditCMSWrapper } from "../../../reusable components/EditCMS/EditCMSWrapper"

function ServiceComponent() {
  return (
    <section id="service_home" className="clearfix">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="service_h1 text-center">
              <h2>
                <EditCMSWrapper dataKey="heroTitle" />
              </h2>
              <span className="block_icon">
                <i className="fa fa-leaf" />
              </span>
              <p>
                <EditCMSWrapper dataKey="heroDesc" />
              </p>
            </div>
          </div>
          <div className="col-sm-12 space_all">
            <div className="service_h2 clearfix">
              <div className="col-sm-3">
                {["leaf", "tree", "truck"].map((icon, index) => (
                  <div
                    className="service_h2i clearfix"
                    key={`service_left_${index}`}
                  >
                    <div className="service_h2i1 clearfix">
                      <h4>
                        <EditCMSWrapper dataKey={`serviceLeftTitle_${index}`} />
                      </h4>
                      <hr />
                      <p>
                        <EditCMSWrapper dataKey={`serviceLeftDesc_${index}`} />
                      </p>
                    </div>
                    <div className="service_h2i2 clearfix">
                      <span>
                        <i className={`fa fa-${icon}`} />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-sm-6">
                <div className="service_h2ic text-center">
                  <EditCMSWrapper type="image" dataKey="serviceCenterImg" />
                </div>
              </div>
              <div className="col-sm-3">
                {["male", "female", "phone"].map((icon, index) => (
                  <div
                    className="service_h2i clearfix"
                    key={`service_right_${index}`}
                  >
                    <div className="service_h2i1 clearfix">
                      <h4>
                        <EditCMSWrapper
                          dataKey={`serviceRightTitle_${index}`}
                        />
                      </h4>
                      <hr />
                      <p>
                        <EditCMSWrapper dataKey={`serviceRightDesc_${index}`} />
                      </p>
                    </div>
                    <div className="service_h2i2 clearfix">
                      <span>
                        <i className={`fa fa-${icon}`} />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceComponent
