import { jwtDecode } from "jwt-decode"
import { toast } from "react-toastify"
import { getContext, setContext } from "../context/AppContext"
import { useEffect } from "react"
import { navigateTo } from "../App"
import { useNavigate } from "react-router-dom"

export const validateToken = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const { loggedUserData } = getContext()

    if (loggedUserData?.loginToken) {
      const decodedToken = jwtDecode(loggedUserData.loginToken)
      const currentTime = Math.floor(Date.now() / 1000)

      // Check token expiration
      if (decodedToken.exp < currentTime) {
        // Token expired, clear data
        localStorage.removeItem("loggedUserData")
        toast.error("Session expired, please login again.")
        setContext({ loggedUserData: null })
        navigateTo("/")
      }
    } else {
      // No token, clear data
      localStorage.removeItem("loggedUserData")
      setContext({ loggedUserData: null })
    }
  }, [navigate])
}
