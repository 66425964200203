import React, { useEffect, useState } from "react"
import LoginIcon from "@mui/icons-material/Login"
import { Link } from "react-router-dom"
import { EditCMSWrapper } from "../../reusable components/EditCMS/EditCMSWrapper"
import { LanguageSwitcher } from "../../reusable components/i18n/LanguageSwitcher"
import LinkI18n from "../../reusable components/i18n/LinkI18n"

export const Header = ({ Facebook, LinkedIn, Twitter }) => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const secondaryNav = document.querySelector(".cd-secondary-nav")
      const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 0

      if (window.scrollY > (secondaryNav?.offsetTop || 0)) {
        secondaryNav?.classList.add("is-fixed")
        document.body.style.paddingTop = `${navbarHeight}px`
      } else {
        secondaryNav?.classList.remove("is-fixed")
        document.body.style.paddingTop = "0"
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      document.body.style.paddingTop = "0"
    }
  }, [])

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed)
  }

  return (
    <>
      <section id="top">
        <div className="container">
          <div className="row">
            <div className="top_1 clearfix">
              <div className="col-sm-4">
                <div className="top_1l clearfix">
                  <p className="col">
                    <EditCMSWrapper dataKey="header1" />
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <LanguageSwitcher />
              </div>
              <div className="col-sm-4">
                <div className="top_1r clearfix text-right">
                  <ul className="social-network social-circle mgt">
                    <li>
                      <Link to="https://www.facebook.com/">
                        <i className="fa fa-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.twitter.com/">
                        <i className="fa fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://linkedin.com/">
                        <i className="fa fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="header" className="clearfix cd-secondary-nav">
        <nav className="navbar nav_t">
          <div className="container">
            <div className="navbar-header page-scroll">
              <button
                type="button"
                className="navbar-toggle"
                onClick={toggleNavbar}
                aria-expanded={!isNavbarCollapsed}
                aria-controls="navbarResponsive"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <Link className="navbar-brand" to="/">
                <i className="fa fa-leaf col_1" />
                <EditCMSWrapper dataKey="header10" />
                <br />
                <span className="col_2 font_14">
                  <EditCMSWrapper dataKey="header11" />
                </span>
              </Link>
            </div>
            <div
              className={`collapse navbar-collapse ${
                isNavbarCollapsed ? "" : "in"
              }`}
              id="navbarResponsive"
            >
              <ul className="nav navbar-nav">
                <li>
                  <LinkI18n
                    className="m_tag"
                    to="/"
                    onClick={() => setIsNavbarCollapsed(true)}
                  >
                    Home
                  </LinkI18n>
                </li>
                <li>
                  <LinkI18n
                    className="m_tag"
                    to="/about"
                    onClick={() => setIsNavbarCollapsed(true)}
                  >
                    About
                  </LinkI18n>
                </li>
                <li>
                  <LinkI18n
                    className="m_tag"
                    to="/services"
                    onClick={() => setIsNavbarCollapsed(true)}
                  >
                    Services
                  </LinkI18n>
                </li>
                <li>
                  <LinkI18n
                    className="m_tag"
                    to="/project"
                    onClick={() => setIsNavbarCollapsed(true)}
                  >
                    Project
                  </LinkI18n>
                </li>
                {/* <li>
      <Link className="m_tag" to="/faq" onClick={() => setIsNavbarCollapsed(true)}>
        FAQ
      </Link>
    </li> */}
                <li>
                  <LinkI18n
                    className="m_tag"
                    to="/contact"
                    onClick={() => setIsNavbarCollapsed(true)}
                  >
                    Contact Us
                  </LinkI18n>
                </li>
              </ul>
              <ul className="nav navbar-nav navbar-right nav_1">
                <li>
                  <Link to="/login">
                    <LoginIcon sx={{ fontSize: 32 }} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  )
}
