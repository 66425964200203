import React, { useState } from "react"
import {
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import TypographyComponent from "../i18n/TypographyComponent"

const CardComponent = ({
  title,
  variant,
  children,
  accordionContent,
  withAccordion = false,
  sx = {},
  disableAnimation = true,
}) => {
  const [hoveredCard, setHoveredCard] = useState(null)

  const handleMouseOver = () => setHoveredCard(true)
  const handleMouseOut = () => setHoveredCard(false)

  const cardStyles = {
    width: "100%",
    // maxWidth: 400,
    textAlign: "center",
    transition: disableAnimation ? "none" : "transform 0.3s, box-shadow 0.3s",
    transform: hoveredCard && !disableAnimation ? "scale(1.05)" : "scale(1)",
    boxShadow: hoveredCard && !disableAnimation ? "0px 8px 16px rgba(0,0,0,0.2)" : "none",
    margin: "auto",
    ...sx,
  }

  const cardContent = (
    <Card
      variant={variant}
      sx={cardStyles}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TypographyComponent variant="h6" gutterBottom>
          {title}
        </TypographyComponent>
        {children}
        {accordionContent && withAccordion && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <TypographyComponent>More Details</TypographyComponent>
            </AccordionSummary>
            <AccordionDetails>{accordionContent}</AccordionDetails>
          </Accordion>
        )}
      </CardContent>
    </Card>
  )

  return withAccordion ? (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TypographyComponent>{title}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>{cardContent}</AccordionDetails>
    </Accordion>
  ) : (
    cardContent
  )
}

export default CardComponent
