import * as React from "react"
import { useTheme } from "@mui/material/styles"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { LineChart } from "@mui/x-charts/LineChart"
import { useState } from "react"
import TypographyComponent from "../../../reusable components/i18n/TypographyComponent"
import { translateCustom } from "../../../reusable components/i18n/i18n"

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  )
}

export default function SessionsChart({
  title = "Sessions",
  subTitle = "",
  dataset,
  colorPalette,
  series,
  xAxis,
  gradients,
}) {
  const theme = useTheme()
  const [visibleSeries, setVisibleSeries] = useState<string | null>(null)

  const toggleSeries = (serieId: string) => {
    setVisibleSeries((prev) => (prev === serieId ? null : serieId))
  }

  const filteredSeries = series.filter(
    (serie) => visibleSeries === null || serie.dataKey === visibleSeries
  )

  const dynamicStyles = gradients.reduce((styles, gradient) => {
    styles[`& .MuiAreaElement-series-${gradient.id}`] = {
      fill: `url('#${gradient.id}')`,
    }
    return styles
  }, {})

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Stack sx={{ justifyContent: "space-between" }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: "center", sm: "flex-start" },
              alignItems: "center",
              gap: 1,
            }}
          >
            <TypographyComponent variant="h4" component="p">
              {title}
            </TypographyComponent>
          </Stack>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {subTitle}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          {series.map((serie) => (
            <Chip
              key={serie.dataKey}
              label={translateCustom(serie.label)}
              onClick={() => toggleSeries(serie.dataKey)}
              color={visibleSeries === serie.dataKey ? "primary" : "default"}
            />
          ))}
        </Stack>
        <LineChart
          dataset={dataset}
          // colors={colorPalette}
          xAxis={xAxis}
          series={filteredSeries}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          sx={dynamicStyles}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          {gradients.map((gradient) => (
            <AreaGradient
              key={gradient.id}
              color={gradient.color}
              id={gradient.id}
            />
          ))}
        </LineChart>
      </CardContent>
    </Card>
  )
}
