import React, { useEffect, useState } from "react"
import HT_EC from "./HT_EC"
import LightRadiationPage from "./LightRadiationPage"
import ManageTerrainsPage from "./ManageTerrainsPage"
import ManageUsersPage from "./ManageUsersPage"
import NPK_Page from "./NPK_Page"
import WindSpeedPage from "./WindSpeedPage"

import ThermostatIcon from "@mui/icons-material/Thermostat"

import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import { Home } from "./DashboardHome"

import AirIcon from "@mui/icons-material/Air"
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf"
import GroupIcon from "@mui/icons-material/Group"
import LightModeIcon from "@mui/icons-material/LightMode"
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"
import TerrainIcon from "@mui/icons-material/Terrain"
import Dashboard from "../Dashboard/Dashboard"

import { Navigate, Route } from "react-router-dom"
import {
  fetchLux,
  fetchNPK,
  fetchSoil,
  fetchWind,
  logoutFunction,
  readResources,
} from "../../api/axiosFunctions"
import { getDynamicContext, setContext } from "../../context/AppContext"

import FlipToFrontIcon from "@mui/icons-material/FlipToFront"
import SettingsPage from "./SettingsPage"
import { notificationWarningsProcess, prepareData } from "../../api/data"
import LoadingScreen from "../../reusable components/LoadingScreen/LoadingScreen"

const mainListItems = [
  {
    text: "Home",
    icon: <HomeRoundedIcon />,
    link: "home",
    reactElement: <Home />,
  },
  {
    text: "Front Page",
    icon: <FlipToFrontIcon />,
    link: "../",
    reactElement: <Home />,
  },
  {
    type: "DIVIDER",
    text: "Sensors",
  },
  {
    text: "HT_EC",
    icon: <ThermostatIcon />,
    link: "HT_EC",
    reactElement: <HT_EC />,
  },
  {
    text: "NPK",
    icon: <EnergySavingsLeafIcon />,
    link: "npk",
    reactElement: <NPK_Page />,
  },
  {
    text: "Wind Speed",
    icon: <AirIcon />,
    link: "wind_speed",
    reactElement: <WindSpeedPage />,
  },
  {
    text: "Light Radiation",
    icon: <LightModeIcon />,
    link: "light_radiation",
    reactElement: <LightRadiationPage />,
  },
]
const secondaryListItems = [
  {
    text: "Settings",
    icon: <SettingsRoundedIcon />,
    link: "settings",
    reactElement: <SettingsPage />,
  },
  {
    type: "DIVIDER",
    text: "Admin",
    role: ["Project Manager", "Admin"],
  },
  {
    text: "Manage Terrains",
    icon: <TerrainIcon />,
    link: "manage_terrains",
    reactElement: <ManageTerrainsPage />,
    role: ["Project Manager"],
  },
  {
    text: "Manage Users",
    icon: <GroupIcon />,
    link: "manage_users",
    reactElement: <ManageUsersPage />,
    role: ["Project Manager", "Admin"],
  },
]

const optionsMenuItems = [
  {
    label: "Settings",
    link: "./setting",
  },
  {
    label: "Divider", // Marks where a divider should be added
  },
  {
    label: "Home page",
    link: "/",
  },
  {
    label: "Logout",
    link: "/login",
    onClick: null, // Handle logout separately via context
    Icon: <LogoutRoundedIcon fontSize="small" />, // Handle logout separately via context
  },
]

function DashboardInit() {
  const [loading, setLoading] = useState(true)
  const { terrains, loggedUserData, NPK_Data, luxData, HT_EC_Data, windData } =
    getDynamicContext()

  const notificationData = !loading
    ? notificationWarningsProcess({ NPK_Data, luxData, HT_EC_Data, windData })
    : []

  const projectContent = terrains.map((terrain) => ({
    value: terrain.id,
    icon: <TerrainIcon sx={{ fontSize: "1rem" }} />,
    avatarAlt: terrain.name,
    primary: terrain.name,
    secondary: terrain.address,
    onClick: () => console.log("Sitemark-app selected"),
  }))

  useEffect(() => {
    const fetchData = async () => {
      const terrains = await readResources("terrains")

      setContext({
        terrains,
        NPK_Data: await fetchNPK(),
        luxData: await fetchLux(),
        HT_EC_Data: await fetchSoil(),
        windData: await fetchWind(),
      })
      setLoading(false)
    }

    if (loggedUserData) fetchData()
  }, [loggedUserData])

  return (
    <Route
      path="/dashboard"
      element={
        <ProtectedRoute>
          {loading ? (
            <LoadingScreen />
          ) : (
            <Dashboard
              siteName="Agriculture"
              notificationData={notificationData}
              projectContent={projectContent}
              loggedUserData={loggedUserData}
              logoutFunction={logoutFunction}
              optionsMenuItems={optionsMenuItems}
              mainListItems={mainListItems}
              secondaryListItems={secondaryListItems}
              setContext={setContext}
              logo="/logo.png"
            />
          )}
        </ProtectedRoute>
      }
    >
      {[...mainListItems, ...secondaryListItems].map((item, key) => (
        <Route
          key={key}
          path={item.link}
          element={
            item.role ? (
              <RoleProtectedRoute allowedRoles={item.role}>
                {item.reactElement}
              </RoleProtectedRoute>
            ) : (
              item.reactElement
            )
          }
        />
      ))}
    </Route>
  )
}

// ProtectedRoute: Redirects to sign-in if the user is not logged in
const ProtectedRoute = ({ children }) => {
  const { loggedUserData } = getDynamicContext()

  if (!loggedUserData) {
    return <Navigate to="/login" replace />
  }
  return children
}

// RoleProtectedRoute: Restricts access based on user roles
const RoleProtectedRoute = ({ allowedRoles, children }) => {
  const { loggedUserData } = getDynamicContext()

  if (!allowedRoles.includes(loggedUserData.role)) {
    return <Navigate to="/dashboard/home" replace />
  }
  return children
}
export default DashboardInit
