import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Autoplay } from "swiper/modules"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { EditCMSWrapper } from "../EditCMS/EditCMSWrapper"

const CarouselComponent = ({ slides, height = "400px" }) => {
  return (
    <div style={{ margin: "0 auto", height }}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
        style={{ height: "100%" }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                ...styles.slideContainer,
                height,
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div style={styles.textOverlay}>
                <h1 className="mgt col">
                  <EditCMSWrapper dataKey={slide.title} />
                </h1>
                <p className="font_20 col_3">
                  <EditCMSWrapper dataKey={slide.description} />
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

const styles = {
  slideContainer: {
    position: "relative",
    textAlign: "center",
    borderRadius: "8px",
    overflow: "hidden", // Ensures content doesn't spill out of the container
  },
  textOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "left",
    color: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "70%",
  },
}

export default CarouselComponent
