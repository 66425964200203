import { Grid2 } from "@mui/material"
import React from "react"
import CardComponent from "../Card/CardComponent"
import "./HumidityDrop.css"; // Create a CSS file for styles

const HumidityDrop = ({
  value,
 title ="Humidity Monitor"
}) => {

  // Calculate height and determine colors dynamically
  const fillHeight = (value / 100) * 200 // Filling height relative to the drop size
  let background = ""
  let textColor = ""

  if (value < 30) {
    background = "linear-gradient(to top, #ffeb3b, #ff9800)" // Low humidity
    textColor = "#ff9800"
  } else if (value < 70) {
    background = "linear-gradient(to top, #2196f3, #1e88e5)" // Normal humidity
    textColor = "#1e88e5"
  } else {
    background = "linear-gradient(to top, #f44336, #d32f2f)" // High humidity
    textColor = "#d32f2f"
  }

  return (
    <CardComponent title={title}>
      <Grid2 container >
        {/* <Grid2  >
          <TypographyComponent>{sideText}</TypographyComponent>{" "}
        </Grid2> */}
        <Grid2>
          <div className="drop-container">
            {/* Water drop */}
            <div className="drop">
              {/* Humidity level inside the drop */}
              <div
                className="humidity-level"
                style={{
                  height: `${fillHeight}px`,
                  background,
                }}
              />
            </div>
            {/* Display humidity value */}
            <div className="humidity-value" style={{ color: textColor }}>
              Humidity : {value}
            </div>
          </div>
        </Grid2>
      </Grid2>
    </CardComponent>
  )
}

export default HumidityDrop
