import React from "react"
import ProjectComponent from "./components/ProjectComponent"
import { translateCustom } from "../../reusable components/i18n/i18n"

export const Project = () => {
  return (
    <>
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="center_o1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">{translateCustom("Our Projects")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectComponent />
    </>
  )
}
