import React from "react"
import {  getDynamicContext } from "../../context/AppContext"
import DataGridComponent from "../../reusable components/DataGridCRUD/DataGridCrud"
import QR_Code from "../../reusable components/QR Code/QR_Code"

function ManageUsersPage() {
  const { terrains } = getDynamicContext()

  const getTerrainNameById = (id) => {
    const terrain = terrains.find((terrain) => terrain.id === Number(id))
    return terrain ? terrain.name : "Unknown Terrain"
  }

  const columns = [
    // { field: "id", headerName: "ID", width: 90, formHidden: true },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      required: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      required: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorMessage: "Invalid email format",
      required: true,
      type: "email",
    },
    {
      type: "tel",
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      pattern: "\\d*",
      maxLength: 15,
      minLength: 8,
      errorMessage: "Phone number must be 8-15 digits",
    },
    {
      field: "password",
      headerName: "Password",
      width: 150,
      hidden: true,
      type: "password", // Indicates it's a password field
      maxLength: 50,
      minLength: 6,
      errorMessage: "Password must be at least 6 characters",
      required: true,
    },
    {
      field: "terrain_id",
      headerName: "Terrain",
      width: 200,
      required: true,
      type: "select",
      options: terrains.map((option) => ({
        value: option.id,
        label: option.name,
      })),
      valueGetter: (params, row) => getTerrainNameById(row.terrain_id), // Get terrain name by ID
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      required: true,
      type: "select",
      options: [
        { value: "Admin", label: "Admin" },
        { value: "User", label: "User" },
      ], // Static options for the role
    },
    {
      field: "qrCode",
      headerName: "QR code",
      width: 150,
      formHidden: true,
      renderCell: (params) => <QR_Code value={window.location.origin + "/login?qrCode=" + params.row?.qrCode} />,
    },
  ]

  return <DataGridComponent columns={columns} tableName="users" />
}

export default ManageUsersPage
