import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Stack from "@mui/material/Stack"
import * as React from "react"
import { Link, useLocation } from "react-router-dom"
import { dashboardContext } from "../Dashboard"
import { Divider } from "@mui/material"
import { translateCustom } from "../../../reusable components/i18n/i18n"

export default function MenuContent() {
  const { mainListItems, secondaryListItems, loggedUserData } =
    React.useContext(dashboardContext)
  const location = useLocation()

  // Role-based filtering
  const filterByRole = (items) => {
    return items.filter((item) => {
      if (!item.role) return true // No role restriction
      return item.role.some((role) => loggedUserData?.role === role)
    })
  }

  const renderMenuItems = (items) => {
    return items.map((item, index) => {
      if (item.type === "DIVIDER") {
        return (
          <Divider key={`divider-${index}`}>
            {translateCustom(item.text)}
          </Divider>
        )
      }

      const isSelected = location?.pathname === `/${item?.link}`

      return (
        <Link key={index} to={item?.link} style={{ textDecoration: "none" }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton selected={isSelected}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={translateCustom(item.text)} />
            </ListItemButton>
          </ListItem>
        </Link>
      )
    })
  }

  // Filter main and secondary items
  const filteredMainListItems = filterByRole(mainListItems)
  const filteredSecondaryListItems = filterByRole(secondaryListItems)

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>{renderMenuItems(filteredMainListItems)}</List>

      <List dense>{renderMenuItems(filteredSecondaryListItems)}</List>
    </Stack>
  )
}
