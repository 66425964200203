import React from "react"
import { Navigate } from "react-router-dom"
import { getContext } from "./context/AppContext"

function RedirectIfAuthenticated({ children }) {
  const { loggedUserData } = getContext()

  // If the user is already logged in, redirect to the dashboard
  if (loggedUserData) {
    return <Navigate to="/dashboard/home" replace />
  }

  // Otherwise, render the children (e.g., SignIn component)
  return children
}

export default RedirectIfAuthenticated
