import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  Link,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import MuiCard from "@mui/material/Card";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import AppTheme from "./theme/AppTheme";
import ColorModeSelect from "./theme/ColorModeSelect";
import { LanguageSwitcher } from "../i18n/LanguageSwitcher";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const ResetPasswordContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export const ResetPassword = (props: {
  disableCustomTheme?: boolean;
  siteName: string;
  logo: any;
  resetPasswordFunction: (data: { token: string; newPassword: string }) => Promise<boolean>;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateFields = (password: string) => {
    let isValid = true;

    // Password validation
    if (!password || password.length < 8) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 8 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPassword = data.get("newPassword") as string;

    const token = searchParams.get("token");
    if (!token) {
      alert("Invalid or missing token.");
      return;
    }

    if (validateFields(newPassword)) {
      const success = await props.resetPasswordFunction({ token, newPassword });

      if (success) {
        setSuccessMessage("Password reset successfully. Redirecting to login...");
        setTimeout(() => navigate("/login"), 5000);
      } else {
        alert("Failed to reset password. Please try again.");
      }
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <ResetPasswordContainer direction="column" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%", flexDirection: { sm: "row" } }}
        >
          <ButtonComponent
            Icon={<ArrowBackIcon />}
            tooltipText="Go back"
            onClick={() => navigate("/")}
          />
          <LanguageSwitcher />
          <ColorModeSelect />
        </Stack>
        <Card variant="outlined">
          <Typography component="h1" variant="h4">
            Reset Password
          </Typography>
          {successMessage && (
            <Typography variant="body2" color="success.main">
              {successMessage}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="newPassword">New Password</FormLabel>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                id="newPassword"
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                autoComplete="new-password"
                required
                fullWidth
              />
            </FormControl>
            <Link href="./login" variant="body2" sx={{ alignSelf: "flex-end" }}>
              Login
            </Link>
            <Button type="submit" fullWidth variant="contained">
              Reset Password
            </Button>
          </Box>
        </Card>
      </ResetPasswordContainer>
    </AppTheme>
  );
};
