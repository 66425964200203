import { Box } from "@mui/material"
import Grid from "@mui/material/Grid2"
import React from "react"
import { getDynamicContext } from "../../context/AppContext"
import TypographyComponent from "../../reusable components/i18n/TypographyComponent"
import RectangleBox from "../../reusable components/Rectangle Box/RectangleBox"
export const Home = () => {
  // Get the latest data from the context
  const { NPK_Data, luxData, HT_EC_Data, windData } = getDynamicContext()

  // Extract last records for each dataset
  const lastNPK = NPK_Data.at(-1) // NPK: Last record
  const lastLux = luxData.at(-1) // Lux: Last record
  const lastHTEC = HT_EC_Data.at(-1) // Humidity/Temperature/EC: Last record
  const lastWind = windData.at(-1) // Wind: Last record

  const data = [
    {
      title: "humidity_temperature_ec_title",
      subtitle: "humidity_temperature_ec_description",
      currentValue: `${lastHTEC?.temperature}°C`,
      imageUrl: "/images/dashboard/temperature.webp",
      linkTo: "../HT_EC",
    },
    {
      title: "npk_levels_title",
      subtitle: "npk_levels_description",
      currentValue: `N: ${lastNPK?.nitrogen}, P: ${lastNPK?.phosphorus}, K: ${lastNPK?.potassium}`,
      imageUrl: "/images/dashboard/NPK.webp",
      linkTo: "../npk",
    },
    {
      title: "wind_speed_title",
      subtitle: "wind_speed_description",
      currentValue: `${lastWind?.speed} km/h`,
      imageUrl: "/images/dashboard/wind_speed.webp",
      linkTo: "../wind_speed",
    },
    {
      title: "light_radiation_title",
      subtitle: "light_radiation_description",
      currentValue: `${lastLux?.intensity} W/m²`,
      imageUrl: "/images/dashboard/light_radiation.webp",
      linkTo: "../light_radiation",
    },
  ]


  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <TypographyComponent component="h2" variant="h6" sx={{ mb: 2 }}>
        Summary
      </TypographyComponent>
      <Grid container spacing={2} columns={12} sx={{ mb: 2 }}>
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 6 }}>
            <RectangleBox {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
