import React from "react"
import { Link } from "react-router-dom" // Use react-router-dom for Link
import QualityComponent from "./components/QualityComponent"
import { EditCMSWrapper } from "../../reusable components/EditCMS/EditCMSWrapper"
import { translateCustom } from "../../reusable components/i18n/i18n"

export const Services = () => {
  return (
    <>
      {/* Page Header Section */}
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="center_o1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">{translateCustom("Our Services")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="plan">
        <div className="container">
          <div className="row">
            <div className="plan_1 clearfix">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <div className="col-sm-4" key={index}>
                    <div className="plan_1_i text-center clearfix">
                      <EditCMSWrapper
                        imageProps={{ className: "iw", height: 250 }}
                        type="image"
                        dataKey={`serviceA${index}`}
                      />
                      <h4>
                        <EditCMSWrapper dataKey={`serviceA${index}`} />{" "}
                      </h4>
                      <p>
                        <EditCMSWrapper dataKey={`serviceAA${index}`} />{" "}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      {/* Connect Section */}
      <section id="connect">
        <div className="container">
          <div className="row">
            <div className="connect_1 clearfix">
              <div className="col-sm-10">
                <div className="connect_1l clearfix">
                  <p className="mgt col">
                    <EditCMSWrapper dataKey={`service1`} />
                  </p>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="connect_1r clearfix">
                  <h5 className="mgt">
                    <Link className="button mgt" to="/contact">
                      CONTACT
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="connect_2 clearfix">
              {["fa-tree", "fa-user", "fa-hand-lizard-o"].map((icon, index) => (
                <div className="col-sm-4" key={index}>
                  <div className="connect_2i clearfix">
                    <div className="col-sm-2 space_all">
                      <div className="connect_2il clearfix">
                        <span className="col_1">
                          <i className={`fa ${icon}`} />
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <div className="connect_2ir clearfix">
                        <h5 className="mgt">
                          <EditCMSWrapper dataKey={`serviceB${index}`} />
                        </h5>
                        <p>
                          <EditCMSWrapper dataKey={`serviceBB${index}`} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      {/* <section id="about_page_o">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="about_page_l clearfix">
                <h3 className="mgt">Meet our experts</h3>
                <div className="carousel slide" id="myCarousel">
                  <div className="carousel-inner">
                    {[
                      {
                        img: "/images/frontPageImages/24.jpg",
                        name: "Semper Porta",
                        position: "Senior Vegan Worker",
                      },
                      {
                        img: "/images/frontPageImages/25.jpg",
                        name: "Lacinia Arcu",
                        position: "Senior Vegan Worker",
                      },
                    ].map((expert, index) => (
                      <div
                        className={`item ${index === 0 ? "active" : ""}`}
                        key={index}
                      >
                        <ul className="thumbnails">
                          <li className="col-sm-12 space_left">
                            <div className="program_1i clearfix">
                              <img
                                src={expert.img}
                                alt={expert.name}
                                className="iw"
                              />
                              <div className="environment_2i clearfix">
                                <h4 className="mgt">{expert.name}</h4>
                                <h5>{expert.position}</h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-8">
              <div className="about_page_r clearfix">
                <h3 className="mgt">Construction Finance FAQs</h3>
                <div className="panel panel-success">
                  <div className="panel-heading">
                    <h5 className="panel-title">
                      What new products are you planning?
                    </h5>
                  </div>
                  <div className="panel-body">
                    Our registered office is in London, but the nerve center is
                    with our underwriting team in Kent.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <QualityComponent />
    </>
  )
}
