import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  Link,
  Stack,
  TextField,
  Typography,
  styled
} from "@mui/material"
import MuiCard from "@mui/material/Card"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ButtonComponent } from "../ButtonComponent/ButtonComponent"
import AppTheme from "./theme/AppTheme"
import ColorModeSelect from "./theme/ColorModeSelect"
import { LanguageSwitcher } from "../i18n/LanguageSwitcher"
import TypographyComponent from "../i18n/TypographyComponent"
import LinkI18n from "../i18n/LinkI18n"
import ButtonI18n from "../ButtonComponent/ButtonI18n"

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}))

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}))

export const ForgotPassword = (props: {
  disableCustomTheme?: boolean
  siteName: string
  logo: any
  forgetPasswordFunction: (data: object) => void
}) => {
  const navigate = useNavigate()
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("")


  const validateFields = (email: string) => {
    let isValid = true

    // Email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true)
      setEmailErrorMessage("Please enter a valid email address.")
      isValid = false
    } else {
      setEmailError(false)
      setEmailErrorMessage("")
    }


    return isValid
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get("email") as string

    if (validateFields(email)) {
      props.forgetPasswordFunction({ email })
    }
  }

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
      <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%", flexDirection: { sm: "row" } }}
        >
          <ButtonComponent
            Icon={<ArrowBackIcon />}
            tooltipText="Go back"
            onClick={() => navigate("/")}
          />
          <LanguageSwitcher />
          <ColorModeSelect />
        </Stack>
        <Card variant="outlined">
          <TypographyComponent component="h1" variant="h4">
            Forgot Password
          </TypographyComponent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
              />
            </FormControl>
            <LinkI18n href="./login" variant="body2" sx={{ alignSelf: "flex-end" }}>
              Login
            </LinkI18n>
            <ButtonI18n type="submit" fullWidth variant="contained">
              Send email
            </ButtonI18n>
          </Box>
        </Card>
      </SignInContainer>
    </AppTheme>
  )
}
