import { Box, Typography } from "@mui/material"
import React, { useState } from "react"
import { updateSelfUser } from "../../api/axiosFunctions"
import { getContext } from "../../context/AppContext"
import ReusableForm from "../../reusable components/ReusableForm/ReusableForm"
import TypographyComponent from "../../reusable components/i18n/TypographyComponent"

const SettingsPage = () => {
  const { loggedUserData } = getContext()
  const [formState, setFormState] = useState(loggedUserData)

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      required: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      required: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      pattern: /^[0-9]{8,15}$/,
      errorMessage: "Phone number must be 8-15 digits",
    },
    {
      field: "newPassword",
      headerName: "New password",
      type: "password", // Indicates it's a password field
      pattern: /^.{6,}$/,
      errorMessage: "Password must be at least 6 characters",
    },
    {
      field: "confirmPassword",
      headerName: "Confirm new password",
      type: "password", // Indicates it's a password field
      pattern: /^.{6,}$/,
      errorMessage: "Password must be at least 6 characters",
    },
  ]

  const handleSubmit = () => updateSelfUser(formState)

  return (
    <Box>
      <TypographyComponent variant="h4" gutterBottom>
        Settings
      </TypographyComponent>
      <ReusableForm {...{ formState, setFormState, columns, handleSubmit }} />
    </Box>
  )
}

export default SettingsPage
