import AirIcon from '@mui/icons-material/Air'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf"
import WarningIcon from "@mui/icons-material/Warning"
import dayjs from "dayjs"
import React from "react"

// Function to filter and get the last record of each day for the past 30 days
export function getLastRecordsFor30Days(data) {
  const now = dayjs()
  const startDate = now.subtract(30, "day")

  // Filter records within the last 30 days
  const recentRecords = data.filter((record) =>
    dayjs(record.timestamp).isBetween(startDate, now, "day", "[]")
  )

  // Group records by date
  const groupedByDate = recentRecords.reduce((acc, record) => {
    const dateKey = dayjs(record.timestamp).format("YYYY-MM-DD")
    if (!acc[dateKey]) acc[dateKey] = []
    acc[dateKey].push(record)
    return acc
  }, {})

  // Get the last record for each day
  const lastRecords = Object.keys(groupedByDate).map((date) => {
    const recordsForDay = groupedByDate[date]
    return recordsForDay[recordsForDay.length - 1] // Last record of the day
  })

  return lastRecords
}

export function getHighestValuesByMonth(data, properties) {
  const result = {}

  data.forEach((entry) => {
    const month = new Date(entry.timestamp).toISOString().slice(0, 7) // Extract year-month

    if (!result[month]) {
      result[month] = properties.reduce((acc, prop) => {
        acc[prop] = entry[prop]
        return acc
      }, {})
    } else {
      properties.forEach((prop) => {
        if (Number(entry[prop]) > Number(result[month][prop])) {
          result[month][prop] = entry[prop]
        }
      })
    }
  })

  return Object.keys(result).map((month) => ({
    month,
    ...result[month],
  }))
}

function addMonth(rows) {
  return rows.map((row) => ({
    ...row,
    month: dayjs(row.timestamp).format("MMM"),
  }))
}

function convertStringToNumbers(rows, properties) {
  return rows.map((row) => {
    const updatedRow = { ...row }
    properties.forEach((prop) => {
      if (row[prop] !== undefined) {
        updatedRow[prop] = parseFloat(row[prop])
      }
    })
    return updatedRow
  })
}

export function changePropertiesInArray(arr, propMap) {
  return arr.map((obj) => {
    let newObj = { ...obj } // Create a shallow copy to avoid mutating the original object
    Object.entries(propMap).forEach(([oldProp, newProp]) => {
      if (newObj.hasOwnProperty(oldProp)) {
        newObj[newProp] = newObj[oldProp]
        delete newObj[oldProp]
      }
    })
    return newObj
  })
}

const addInterpretation = (array) => {
  const threshold = {
    nitrogen: {
      optimal: [20, 40],
      low: [10, 20],
      veryLow: [0, 10],
      high: [40, 60],
      veryHigh: [60, Infinity],
    },
    phosphorus: {
      optimal: [5, 20],
      low: [2, 5],
      veryLow: [0, 2],
      high: [20, 40],
      veryHigh: [40, Infinity],
    },
    potassium: {
      optimal: [100, 250],
      low: [50, 100],
      veryLow: [0, 50],
      high: [250, 300],
      veryHigh: [300, Infinity],
    },
    temperature: {
      optimal: [18, 25],
      low: [10, 18],
      veryLow: [0, 10],
      high: [25, 35],
      veryHigh: [35, Infinity],
    },
    humidity: {
      ideal: [25, 50],
      dry: [20, 25],
      veryDry: [0, 20],
      saturated: [50, 80],
      verySaturated: [80, Infinity],
    },
    conductivity: {
      nonSaline: [0, 0.5],
      slightlySaline: [0.5, 1.5],
      moderatelySaline: [1.5, 3.0],
      stronglySaline: [3.0, 5.0],
      verySaline: [5.0, Infinity],
    },
    speed: {
      lightBreeze: [6, 11],
      moderateBreeze: [12, 19],
      strongBreeze: [20, 28],
      moderateStorm: [29, 61],
      violentStorm: [61, 118],
      hurricane: [118, Infinity],
    },
  }

  return array.map((value) => {
    const interpretation = {}
    if (value.nitrogen !== undefined) {
      const n = value.nitrogen
      if (n < threshold.nitrogen.low[0]) {
        interpretation.nitrogen = "Very low"
      } else if (n < threshold.nitrogen.optimal[0]) {
        interpretation.nitrogen = "Low"
      } else if (n <= threshold.nitrogen.optimal[1]) {
        interpretation.nitrogen = "Optimal"
      } else if (n <= threshold.nitrogen.high[1]) {
        interpretation.nitrogen = "High"
      } else {
        interpretation.nitrogen = "Very high"
      }
    }
    if (value.phosphorus !== undefined) {
      const p = value.phosphorus
      if (p < threshold.phosphorus.low[0]) {
        interpretation.phosphorus = "Very low"
      } else if (p < threshold.phosphorus.optimal[0]) {
        interpretation.phosphorus = "Low"
      } else if (p <= threshold.phosphorus.optimal[1]) {
        interpretation.phosphorus = "Optimal"
      } else if (p <= threshold.phosphorus.high[1]) {
        interpretation.phosphorus = "High"
      } else {
        interpretation.phosphorus = "Very high"
      }
    }
    if (value.potassium !== undefined) {
      const k = value.potassium
      if (k < threshold.potassium.low[0]) {
        interpretation.potassium = "Very low"
      } else if (k < threshold.potassium.optimal[0]) {
        interpretation.potassium = "Low"
      } else if (k <= threshold.potassium.optimal[1]) {
        interpretation.potassium = "Optimal"
      } else if (k <= threshold.potassium.high[1]) {
        interpretation.potassium = "High"
      } else {
        interpretation.potassium = "Very high"
      }
    }
    if (value.temperature !== undefined) {
      const temp = value.temperature
      if (temp < threshold.temperature.low[0]) {
        interpretation.temperature = "Very low"
      } else if (temp < threshold.temperature.optimal[0]) {
        interpretation.temperature = "Low"
      } else if (temp <= threshold.temperature.optimal[1]) {
        interpretation.temperature = "Optimal"
      } else if (temp <= threshold.temperature.high[1]) {
        interpretation.temperature = "High"
      } else {
        interpretation.temperature = "Very high"
      }
    }
    if (value.humidity !== undefined) {
      const moisture = value.humidity
      if (moisture < threshold.humidity.dry[0]) {
        interpretation.humidity = "Very dry"
      } else if (moisture < threshold.humidity.ideal[0]) {
        interpretation.humidity = "Dry"
      } else if (moisture <= threshold.humidity.ideal[1]) {
        interpretation.humidity = "Ideal"
      } else if (moisture <= threshold.humidity.saturated[1]) {
        interpretation.humidity = "Saturated"
      } else {
        interpretation.humidity = "Very saturated"
      }
    }
    if (value.conductivity !== undefined) {
      const ec = value.conductivity
      if (ec < threshold.conductivity.slightlySaline[0]) {
        interpretation.conductivity = "Non saline"
      } else if (ec < threshold.conductivity.moderatelySaline[0]) {
        interpretation.conductivity = "Slightly saline"
      } else if (ec < threshold.conductivity.stronglySaline[0]) {
        interpretation.conductivity = "Moderately saline"
      } else if (ec < threshold.conductivity.verySaline[0]) {
        interpretation.conductivity = "Strongly saline"
      } else {
        interpretation.conductivity = "Very saline"
      }
    }
    if (value.speed !== undefined) {
      const wind = value.speed
      if (wind < threshold.speed.moderateBreeze[0]) {
        interpretation.speed = "Light breeze"
      } else if (wind < threshold.speed.strongBreeze[0]) {
        interpretation.speed = "Moderate breeze"
      } else if (wind < threshold.speed.moderateStorm[0]) {
        interpretation.speed = "Strong breeze"
      } else if (wind < threshold.speed.violentStorm[0]) {
        interpretation.speed = "Moderate storm"
      } else if (wind < threshold.speed.hurricane[0]) {
        interpretation.speed = "Violent storm"
      } else {
        interpretation.speed = "Hurricane"
      }
    }
    return { ...value, interpretation }
  })
}

export const notificationWarningsProcess = ({ NPK_Data, luxData, HT_EC_Data, windData }) => {

  // Extract last records for each dataset
  const lastNPK = NPK_Data.at(-1) // NPK: Last record
  const lastLux = luxData.at(-1) // Lux: Last record
  const lastHTEC = HT_EC_Data.at(-1) // Humidity/Temperature/EC: Last record
  const lastWind = windData.at(-1) // Wind: Last record

  const positiveResult = [
    "Ideal",
    "Moderately saline",
    "Moderate breeze",
    "Optimal",
  ]

  // Build the warnings array based on the last record values
  var warnings = [
    {
      primaryText: `Azote (N) (${lastNPK.interpretation.nitrogen})`,
      secondaryText: lastNPK.nitrogen,
      interpretation: lastNPK.interpretation.nitrogen,
      secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
      Icon: <EnergySavingsLeafIcon />,
    },
    {
      primaryText: `Phosphorus (P) (${lastNPK.interpretation.phosphorus})`,
      secondaryText: lastNPK.phosphorus,
      interpretation: lastNPK.interpretation.phosphorus,
      secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
      Icon: <EnergySavingsLeafIcon />,
    },
    {
      primaryText: `Potassium (K) (${lastNPK.interpretation.potassium})`,
      secondaryText: lastNPK.potassium,
      interpretation: lastNPK.interpretation.potassium,
      secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
      Icon: <EnergySavingsLeafIcon />,
    },
    {
      primaryText: `Temperature (${lastHTEC.interpretation.temperature})`,
      secondaryText: lastHTEC.temperature,
      interpretation: lastHTEC.interpretation.temperature,
      secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
      Icon: <DeviceThermostatIcon />,
    },
    {
      primaryText: `Electrical Conductivity (${lastHTEC.interpretation.conductivity})`,
      secondaryText: lastHTEC.conductivity,
      interpretation: lastHTEC.interpretation.conductivity,
      secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
      Icon: <ElectricBoltIcon />,
    },
    {
      primaryText: `Wind Speed (${lastWind.interpretation.speed})`,
      secondaryText: lastWind.speed,
      interpretation: lastWind.interpretation.speed,
      secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
      Icon: <AirIcon />,
    },
    // {
    //   primaryText: `Light Radiation (${lastLux.interpretation.speed})`,
    //   secondaryText: lastLux.speed,
    //   interpretation: lastLux.interpretation.speed,
    //   secondaryIcon: <WarningIcon style={{ fill: "red" }} />,
    //   Icon: <AirIcon />,
    // },
  ]

  warnings = warnings.filter(
    (value) => !positiveResult.includes(value.interpretation)
  )

  return warnings 
}

export function prepareData(rows, properties) {
  if (!rows) return []
  const rowsWithMonth = addMonth(rows)
  const convertedValuesToStringArray = convertStringToNumbers(
    rowsWithMonth,
    properties
  )
  const addedInterpretationArray = addInterpretation(
    convertedValuesToStringArray
  )
  return addedInterpretationArray
}
