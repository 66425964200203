import React from "react"
import { Box, Container, Typography, Grid2 } from "@mui/material"
import { ButtonComponent } from "../ButtonComponent/ButtonComponent"
import AppTheme from "../../components/shared-theme/AppTheme"
import image404 from "./404.png"
export default function NotFoundPage() {
  return (
    <AppTheme>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: 2,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack items on xs, align in a row on md
            justifyContent: { xs: "center", md: "flex-start" }, // Center on xs, align left on md
            alignItems: "center", // Vertically center for all screen sizes
          }}
          maxWidth="md"
        >
          <Grid2 container spacing={2} alignItems="center">
            {/* Text Section */}
            <Grid2
              xs={12}
              md={6}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Typography
                variant="h1"
                sx={{ fontSize: { xs: "3rem", md: "6rem" } }}
              >
                404
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  fontSize: { xs: "1rem", md: "1.25rem" },
                }}
              >
                The page you’re looking for doesn’t exist.
              </Typography>
              <ButtonComponent link="/" variant="contained">
                Back Home
              </ButtonComponent>
            </Grid2>

            {/* Image Section */}
            {/* <Grid2 xs={12} md={6} sx={{ textAlign: "center" }}>
              <img
                src={image404}
                alt="Error Illustration"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </Grid2> */}
          </Grid2>
        </Container>
      </Box>
    </AppTheme>
  )
}
