import React from "react"
import { Button, IconButton, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckIcon from "@mui/icons-material/Check"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import RestartAltIcon from "@mui/icons-material/RestartAlt"

import {
  blueGradient,
  darkBlueGradient,
  greenGradient,
  orangeGradient,
  purpleGradient,
  redGradient,
} from "../../ressources/gradients"
import { useTranslation } from "react-i18next"
import { translateCustom } from "../i18n/i18n"

const gradients = {
  blue: blueGradient,
  green: greenGradient,
  red: redGradient,
  purple: purpleGradient,
  orange: orangeGradient,
  darkBlue: darkBlueGradient,
}

const iconMap = {
  noIcon: <></>,
  add: <AddIcon />,
  edit: <EditIcon />,
  confirm: <CheckIcon />,
  cancel: <CancelIcon />,
  submit: <CheckIcon />,
  delete: <DeleteIcon />,
  reset: <RestartAltIcon />,
}

export const ButtonComponent = (props) => {
  var {
    text: providedText,
    tooltipText,
    Icon,
    link,
    onClick,
    ButtonProps,
    children,
    disabled,
    sx,
  } = props

  const text = translateCustom(providedText || children || undefined)
  tooltipText = translateCustom(tooltipText)
  const isIconButton = !!Icon && !text // Check if only Icon is provided

  const gradient = gradients[props.bgColor || "blue"]

  const styles = {
    width: "100%",
    borderRadius: 20,
    border: 0,
    height: 48,
    padding: "0px 30px",
    margin: "16px 0px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
    transition: "all 0.3s ease-in-out",
    background: gradient,
    fontSize: "1rem",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
      filter: "brightness(115%)",
    },
    "&.Mui-disabled": {
      filter: "grayscale(0.5)",
    },
    ...props.sx,
  }

  const Wrapper = disabled ? "span" : React.Fragment

  if (isIconButton) {
    return (
      <Tooltip sx={props.sx} title={tooltipText}>
        {/* <Wrapper> */}
        <IconButton onClick={onClick} {...ButtonProps} disabled={disabled}>
          {Icon}
        </IconButton>
        {/* </Wrapper> */}
      </Tooltip>
    )
  }

  return (
    <Tooltip title={tooltipText}>
      <Wrapper>
        <Button
          variant={props.variant || "contained"}
          onClick={onClick}
          startIcon={iconMap[text || "noIcon"] || Icon}
          color={props.color}
          sx={{ ...styles, color: "white" }}
          disabled={disabled}
          {...ButtonProps}
        >
          {link ? <Link to={link}>{text}</Link> : text}
        </Button>
      </Wrapper>
    </Tooltip>
  )
}
