import React from "react"
import { EditCMSWrapper } from "../../../reusable components/EditCMS/EditCMSWrapper"

function ProjectComponent() {
  return (
    <section id="project_home">
      <div className="project_m clearfix">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="service_h1 text-center">
                <h2 className="col">
                  <EditCMSWrapper dataKey="projectTitle" />
                </h2>
                <span className="block_icon">
                  <i className="fa fa-leaf" />
                </span>
                <p className="col_3">
                  <EditCMSWrapper dataKey="projectDesc" />
                </p>
              </div>
            </div>
            <div className="col-sm-12 space_all">
              <div className="project_home1 clearfix">
                <div className="tab-content clearfix">
                  <div id="home" className="tab-pane fade clearfix active in">
                    <div className="click clearfix">
                      {[11, 12, 13, 14, 15, 16].map((img, index) => (
                        <div
                          className="col-sm-4 space_all"
                          key={`img_${index}`}
                        >
                          <div className="click_1">
                            <div className="page">
                              <EditCMSWrapper
                                type="image"
                                dataKey={`projectImg${index}`}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Other Tabs */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectComponent
