import React from "react"
import { Card, CardContent, CardMedia, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import Grid from "@mui/material/Grid2"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next" // Import useTranslation
import TypographyComponent from "../i18n/TypographyComponent"
import { translateCustom } from "../i18n/i18n"

interface RectangleBoxProps {
  title: string
  subtitle: string
  currentValue: string
  imageUrl: string
  boxStyle?: React.CSSProperties
  titleStyle?: React.CSSProperties
  subtitleStyle?: React.CSSProperties
  imageStyle?: React.CSSProperties
  currentValueStyle?: React.CSSProperties
  linkTo: string
}

const RectangleBox: React.FC<RectangleBoxProps> = ({
  title,
  subtitle,
  currentValue,
  imageUrl,
  boxStyle,
  titleStyle,
  subtitleStyle,
  imageStyle,
  currentValueStyle,
  linkTo,
}) => {
  return (
    <Link to={linkTo} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          width: "100%",
          height: "auto",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
          },
          ...boxStyle,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <CardMedia
            component="img"
            image={imageUrl}
            alt={translateCustom(title)} // Translate the title
            sx={{
              width: "100%",
              height: "150px",
              objectFit: "contain",
              borderRadius: "8px",
              ...imageStyle,
            }}
          />
          <CardContent sx={{ mt: 2 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "8px",
                ...currentValueStyle,
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                color: "#333",
              }}
            >
              {currentValue}
            </Typography>

            <TypographyComponent
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "8px",
                ...titleStyle,
                fontFamily: "'Inter', sans-serif",
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              {title}
            </TypographyComponent>

            <TypographyComponent
              variant="subtitle1"
              color="textSecondary"
              sx={{
                ...subtitleStyle,
                fontSize: { xs: "0.9rem", sm: "1rem" },
                fontFamily: "'Inter', sans-serif",
              }}
            >
              {subtitle}
            </TypographyComponent>
          </CardContent>
        </Grid>
      </Card>
    </Link>
  )
}

export default RectangleBox
