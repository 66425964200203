import { Paper } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import * as React from "react"
import TypographyComponent from "./reusable components/i18n/TypographyComponent"
import { translateCustom } from "./reusable components/i18n/i18n"

export default function MuiList({ data, title, dense = false }) {
  return (
    <Box sx={{ my: 2 }}>
      <TypographyComponent sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        {title}
      </TypographyComponent>
      <Paper>
        <List dense={dense}>
          {data.map((value, key) => {
            const { primaryText, secondaryText, Icon, secondaryIcon } = value

            return (
              <ListItem
                key={key}
                secondaryAction={
                  <IconButton edge="end">{secondaryIcon}</IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>{Icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={translateCustom(primaryText)}
                  secondary={secondaryText.toString() ? secondaryText : null}
                />
              </ListItem>
            )
          })}
        </List>
      </Paper>
    </Box>
  )
}
