import React from "react"
// import Thermometer from "react-thermometer-component"
import CardComponent from "../Card/CardComponent"
import Thermometer from "./Thermometer"

const ThermometerComponent = (props) => {
  return (
    <CardComponent title={props.title}>
      <Thermometer {...props} />
    </CardComponent>
  )
}

export default ThermometerComponent
