import imageCompression from "browser-image-compression";

/**
 * Converts a File or FileList to Base64 with optional compression.
 * 
 * @param files - A File, FileList, or an array of Files to convert.
 * @param compress - Whether to compress the images before conversion (default: false).
 * @returns A promise that resolves to the Base64 string (or an array of Base64 strings if multiple files are provided).
 */
export async function convertToBase64(
  files: File | FileList | File[],
  compress = false
): Promise<string | string[]> {
  const fileArray: File[] = Array.isArray(files)
    ? files
    : files instanceof FileList
    ? Array.from(files)
    : [files];

  const promises = fileArray.map(async (file) => {
    const processedFile = compress
      ? await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1000,
        })
      : file;

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(processedFile);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  });

  const results = await Promise.all(promises);
  return results.length === 1 ? results[0] : results;
}


/**
 * Validates if the given string is a Base64-encoded image string.
 * 
 * @param str - The string to validate.
 * @returns True if the string is a Base64-encoded image; otherwise, false.
 */
export function isBase64(str: string): boolean {
  if (typeof str !== "string") return false;
  return /^data:image\/[A-Za-z-+\/]+;base64,.+/.test(str);
}


/**
 * Determines whether the given image string is a Base64 string or a file path.
 * If it’s a path, normalizes the backslashes to forward slashes.
 * 
 * @param imageString - The image string to evaluate.
 * @returns The normalized file path or the original Base64 string.
 */
export function base64OrPath(imageString: string | null | undefined): string {
  if (!imageString) return "";

  const isPath = imageString.includes("DBImages");
  return isPath
    ? imageString.replace(/\\/g, "/")
    : imageString;
}
