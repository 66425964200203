import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import Box from "@mui/material/Box"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Paper from "@mui/material/Paper"
import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import {
  createResource,
  deleteResource,
  readResources,
  updateResource,
} from "../../api/axiosFunctions"
import { ButtonComponent } from "../ButtonComponent/ButtonComponent"
import ReusableForm from "../ReusableForm/ReusableForm"
import { translateColumns, translateCustom } from "../i18n/i18n"

const DataGridComponent = ({
  columns,
  pageSizeOptions = [5, 10, 20],
  checkboxSelection = true,
  disableRowSelectionOnClick = true,
  initialPageSize = 10,
  tableName,
}) => {
  columns = translateColumns(columns)
  const [rows, setRows] = useState([])
  const [formState, setFormState] = useState(
    columns.reduce((acc, column) => {
      acc[column.field] = ""
      return acc
    }, {})
  )
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false) // Track unsaved changes
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const fetchedRows = await readResources(tableName)
        setRows(fetchedRows)
        toast.success("Data loaded successfully!")
      } catch (error) {
        toast.error("Failed to load data!")
      }
    })()
  }, [])

  const handleSubmit = async () => {
    try {
      if (formState.id) {
        const updatedRow = await updateResource(tableName, formState)
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === updatedRow.id ? { ...row, ...updatedRow } : row
          )
        )
        toast.success("Row updated successfully!")
      } else {
        const newRow = await createResource(tableName, formState)
        setRows((prevRows) => [...prevRows, newRow])
        toast.success("Row added successfully!")
      }
      handleReset()
    } catch (error) {
      toast.error("Failed to save data!")
    }
  }

  const handleDeleteRow = async (id) => {
    try {
      await deleteResource(tableName, id)
      setRows((prevRows) => prevRows.filter((row) => row.id !== id))
      toast.success("Row deleted successfully!")
    } catch (error) {
      toast.error("Failed to delete row!")
    }
  }

  const handleDeleteSelectedRows = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    })

    if (result.isConfirmed) {
      try {
        await Promise.all(
          selectedRows.map((id) => deleteResource(tableName, id))
        )
        setRows((prevRows) =>
          prevRows.filter((row) => !selectedRows.includes(row.id))
        )
        setSelectedRows([])
        toast.success("Selected rows deleted successfully!")
      } catch (error) {
        toast.error("Failed to delete selected rows!")
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    setHasUnsavedChanges(true) // Mark form as having unsaved changes
  }

  const handleEditRow = (params) => {
    const rowToEdit = rows.find((row) => row.id === params.id)
    if (rowToEdit) {
      setFormState(rowToEdit)
      setHasUnsavedChanges(false) // Reset unsaved changes on edit
    }
  }

  const handleReset = () => {
    setFormState(
      columns.reduce((acc, column) => {
        acc[column.field] = ""
        return acc
      }, {})
    )
    setHasUnsavedChanges(false) // Reset unsaved changes
  }

  const handleClickAway = () => {
    // Disabled
    return
    if (hasUnsavedChanges) {
      // Prevent reset and optionally alert user
      const confirmReset = window.confirm(
        "You have unsaved changes. Are you sure you want to reset?"
      )
      if (!confirmReset) return
    }
    handleReset()
  }

  const handleFillTestData = () => {
    const testData = columns.reduce((acc, column, index) => {
      if (column.field !== "id") {
        if (column.type === "number") {
          acc[column.field] = index + 1
        } else if (
          column.type === "select" &&
          column.options &&
          column.options.length > 0
        ) {
          acc[column.field] = column.options[0].value // Select the first option
        } else if (column.pattern) {
          if (column.field === "email") {
            acc[column.field] = `test${index}@example.com`
          } else if (column.field === "phoneNumber") {
            acc[column.field] = "12345678"
          } else {
            acc[column.field] = `Test ${column.field}`
          }
        } else {
          acc[column.field] = `Test ${column.field}`
        }
      }
      return acc
    }, {})
    setFormState(testData)
    setHasUnsavedChanges(true) // Mark form as having unsaved changes
  }

  const gridColumns = [
    ...columns.filter((column) => !column.hidden),
    {
      field: "actions",
      headerName: translateCustom("Actions"),
      width: 150,
      renderCell: (params) => (
        <>
          <ButtonComponent
            bgColor="blue"
            tooltipText="Edit Row"
            Icon={<EditIcon />}
            onClick={(event) => {
              event.stopPropagation()
              handleEditRow(params)
            }}
          />
          <ButtonComponent
            bgColor="red"
            tooltipText="Delete Row"
            Icon={<DeleteIcon />}
            onClick={(event) => {
              event.stopPropagation()
              handleDeleteRow(params.row.id)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ width: "100%" }}>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={gridColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: initialPageSize,
                  },
                },
              }}
              pageSizeOptions={pageSizeOptions}
              checkboxSelection={checkboxSelection}
              disableRowSelectionOnClick={disableRowSelectionOnClick}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection)
              }}
            />
          </Box>
        </Paper>

        <ButtonComponent
          bgColor="red"
          tooltipText="Delete Selected Rows"
          Icon={<DeleteIcon />}
          onClick={handleDeleteSelectedRows}
          text="Delete Selected"
          fullWidth
          disabled={selectedRows.length === 0} // Disable button when no rows are selected
        />

        <ReusableForm
          {...{
            columns: columns.filter((column) => !column.formHidden),
            formState,
            handleSubmit,
            handleReset,
            handleFillTestData,
            setFormState,
            CRUD: true,
          }}
        />
      </Box>
    </ClickAwayListener>
  )
}

export default DataGridComponent
