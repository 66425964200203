import { toast } from "react-toastify"
import { validateToken } from "./validateToken"

export const axiosInterceptorResponse = (axiosAPI) => {
  axiosAPI.interceptors.response.use(
    function (response) {
      if (response.data.message) toast.success(response.data.message)

      return response.data
    },
    function (error) {
      const errorMessage = error?.response?.data.error
      if (errorMessage === "Invalid or expired token") validateToken()
      toast.error(errorMessage)
      return Promise.reject(error)
    }
  )
}
