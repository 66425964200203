import React from "react"
import { Typography } from "@mui/material"
import { translateCustom } from "./i18n"

const TypographyComponent = ({ children, ...props }) => {
  return (
    <Typography {...props}>
      {typeof children === "string" ? translateCustom(children) : children}
    </Typography>
  )
}

export default TypographyComponent
