import { getContext } from "../context/AppContext"

export function axiosInterceptorSend(axiosAPI) {
  axiosAPI.interceptors.request.use(async (req) => {
    Pace.restart()
    const { loggedUserData } = getContext()

    if (loggedUserData)
      req.headers.authorization = "Bearer " + loggedUserData.loginToken

    return req
  })
}
