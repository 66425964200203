import React, { useRef } from 'react';
import QRCode from 'react-qr-code';

function QR_Code({ value }) {
  const qrRef = useRef();

  console.log(value);
  if (!value) return null;

  const handlePrint = () => {
    const printContent = qrRef.current;
    const printWindow = window.open('', '_blank', 'width=600,height=400');
    if (printWindow && printContent) {
      printWindow.document.write('<html><head><title>Print QR Code</title></head><body>');
      printWindow.document.write(printContent.outerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };

  return (
    <div   onClick={handlePrint} style={{ textAlign: 'center' }}>
      <div
        ref={qrRef}
        style={{ height: 'auto', maxWidth: 50, width: '100%' }}
      >
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={value}
          viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  );
}

export default QR_Code;
