import { Avatar, Stack, Tooltip } from "@mui/material"
import React, { useEffect } from "react"
import { useTheme } from "@mui/material/styles" // Import useTheme
import { useTranslation } from "react-i18next"

import dayjs from "dayjs"
import ar from "dayjs/locale/ar"
import en from "dayjs/locale/en"
import es from "dayjs/locale/es"
import fr from "dayjs/locale/fr"
import it from "dayjs/locale/it"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import i18n from "./i18n"
import arImage from "./locales/tn.png"
import enImage from "./locales/en.png"
import frImage from "./locales/fr.png"

const languages = { en, es, it, fr, ar }

function changeDayjsLanguage(language) {
  dayjs.extend(LocalizedFormat)
  // dayjs.locale({
  //   ...languages[language],
  //   weekStart: 1, // Set Monday as the first day of the week
  // })
}

const changeLocales = (lng) => {
  console.log("Language changed to: " + lng)
  i18n.changeLanguage(lng)
  changeDayjsLanguage(i18n.language)
}

const supportedLanguages = [
  { code: "en", name: "English", flag: enImage },
  { code: "fr", name: "Français", flag: frImage },
  { code: "ar", name: "العربية", flag: arImage },
]

export const LanguageSwitcher = (props) => {
  const { i18n } = useTranslation()
  const theme = useTheme() // Get the theme object

  useEffect(() => {
    changeLocales(i18n.language)
  }, [])

  const isMobile = theme.breakpoints.down("sm")
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        width: "fit-content",
        margin: "auto",
        ...props.sx,
        [isMobile]: {
          spacing: 1, // Reduce spacing between items on small screens
        },
      }}
    >
      {supportedLanguages.map((language, key) => (
        <Tooltip
          key={key}
          onClick={() => changeLocales(language.code)}
          style={{
            cursor: "pointer",
          }}
          title={language.name}
        >
          <Avatar
            sx={{
              outline:
                i18n.language === language.code
                  ? `3px solid ${theme.palette.primary.main}`
                  : "none",
              width: 30,
              height: 30,
              [isMobile]: {
                width: 24,
                height: 24,
              },
            }}
            src={language.flag}
            alt={language.name}
          />
        </Tooltip>
      ))}
    </Stack>
  )
}
