import React, { useEffect, useState } from "react"
import { Stack, TextField } from "@mui/material"
import { convertToBase64 } from "../../utils/convertToBase64"
import { saveWebData } from "../../api/axiosFunctions"
import { ButtonComponent } from "../ButtonComponent/ButtonComponent"
import { useTranslation } from "react-i18next"

export const EditCMS = ({
  dataKey,
  type = "text",
  webData,
  imageProps,
  userRole,
}) => {
  if (typeof webData !== "object") return <></>
  const { t,i18n } = useTranslation()

  const isImage = type === "image"
  const localizedField = isImage ? dataKey : dataKey + "_" + i18n.language
  const [editOn, setEditOn] = useState(false)
  const [data, setData] = useState(webData[localizedField] || "")
  const originalData = webData[localizedField]

  const handleToggle = () => setEditOn(!editOn)

  const handleCancel = () => {
    setEditOn(false)
    setData(originalData || "")
  }

  const handleSave = () => {
    setEditOn(false)
    const updatedData = { [localizedField]: data }
    saveWebData(updatedData)
  }

  const handleImageChange = async (files) => {
    if (!files || files.length === 0) return

    const base64Image = await convertToBase64(files[0], true)

    if (typeof base64Image === "string") {
      setData(base64Image)
      setEditOn(true)
    } else {
      console.error("Unexpected result from convertToBase64: not a string")
    }
  }

  const handleFileExplorerOpen = () => {
    const inputElem = document.getElementById(`changeImage${dataKey}`)
    inputElem?.click()
  }

  useEffect(() => {
    setData(webData[localizedField] || "")
  }, [webData, localizedField])


  const renderContent = () => {
    if (type === "text") {
      return editOn ? (
        <TextField
          fullWidth
          multiline
          rows={4}
          value={data}
          onChange={(e) => setData(e.target.value)}
          variant="outlined"
          sx={{
            backgroundColor: "white",
            color: "black",
            ".MuiInputBase-input": { fontSize: "2rem" },
          }}
        />
      ) : (
        <span
          style={{ marginLeft: "0" }}
          onDoubleClick={
            userRole === "Project Manager" ? handleToggle : undefined
          }
        >
          {t(data || "Placeholder")}
        </span>
      )
    }

    if (type === "image") {
      return (
        <>
          <input
            type="file"
            accept="image/*"
            id={`changeImage${dataKey}`}
            style={{ display: "none" }}
            onChange={(e) => handleImageChange(e.target.files)}
            disabled={userRole !== "Project Manager"}
          />
          <span
            onDoubleClick={
              userRole === "Project Manager"
                ? handleFileExplorerOpen
                : undefined
            }
          >
            {data ? (
              <img
                {...imageProps}
                src={data}
                alt="Selected"
                style={{ maxWidth: "100%" }}
              />
            ) : (
              <img
                {...imageProps}
                src={"https://placehold.co/400x250"}
                alt="Placeholder"
                style={{ maxWidth: "100%" }}
              />
            )}
          </span>
        </>
      )
    }

    return null
  }

  return (
    <>
      {renderContent()}
      {editOn && userRole === "Project Manager" && (
        <Stack direction="row" spacing={2} style={{ marginTop: "10px" }}>
          <ButtonComponent bgColor="green" text="Save" onClick={handleSave} />
          <ButtonComponent bgColor="red" text="Cancel" onClick={handleCancel} />
        </Stack>
      )}
    </>
  )
}

export default EditCMS
