import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const SelectTextField = ({
  headerName,
  field,
  value,
  onChange,
  options,
  required,
}) => {
  return (
    <TextField
      select
      fullWidth
      label={headerName}
      name={field}
      value={value || ""}
      onChange={onChange}
      required={required}
      slotProps={{
        MenuProps: {
          disablePortal: true,
          onClick: (e) => e.stopPropagation(),
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectTextField;
