import React from "react"
import DataGridComponent from "../../reusable components/DataGridCRUD/DataGridCrud"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

function ManageTerrainsPage() {
  return (
    <DataGridComponent
      columns={[
        // { field: "id", headerName: "ID", width: 70 },
        {
          field: "name",
          headerName: "Name",
          width: 150,
          editable: true,
        },
        {
          field: "address",
          headerName: "Address",
          width: 200,
          editable: true,
        },
        {
          field: "google_map_url",
          headerName: "Google Map",
          width: 200,
          editable: true,
          renderCell: (params) => (
            <a
              href={params.row.google_map_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "underline",
              }}
            >
              Open Map
              <OpenInNewIcon style={{ marginLeft: "5px", fontSize: "18px" }} />
            </a>
          ),
        },
        {
          field: "area",
          headerName: "Area (sqm)",
          type: "number",
          width: 150,
          editable: true,
        },
        {
          field: "culture_type",
          headerName: "Culture Type",
          width: 150,
          editable: true,
        },
      ]}
      tableName="terrains"
    />
  )
}

export default ManageTerrainsPage
