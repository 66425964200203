import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { BarChart } from "@mui/x-charts/BarChart"
import * as React from "react"
import CardComponent from "../../../reusable components/Card/CardComponent"
import TypographyComponent from "../../../reusable components/i18n/TypographyComponent"

export default function PageViewsBarChart({ title = "Sessions", subTitle = "",dataset, series, colorPalette}) {

  const theme = useTheme()
  // const colorPalette = [
  //   theme.palette.primary.dark,
  //   theme.palette.primary.main,
  //   theme.palette.primary.light,
  // ]
  return (
    <CardComponent variant="outlined" sx={{ width: "100%" }} withAccordion>
        {/* <Typography component="h2" variant="subtitle2" gutterBottom>{title}</Typography> */}
        <Stack sx={{ justifyContent: "space-between" }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: "center", sm: "flex-start" },
              alignItems: "center",
              gap: 1,
            }}
          >
            <TypographyComponent variant="h4" component="p">
              {title}
            </TypographyComponent>
            {/* <Chip size="small" color="error" label="-8%" /> */}
          </Stack>
         { subTitle && <TypographyComponent variant="caption" sx={{ color: "text.secondary" }}>{subTitle}</TypographyComponent>}
        </Stack>
        <BarChart
          dataset={dataset}
          borderRadius={8}
          colors={colorPalette}
          xAxis={
            [
              {
                scaleType: "band",
                categoryGapRatio: 0.5,
                // data: dataX,
                dataKey: 'month' 
              },
            ]
          }
          series={series}
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
    </CardComponent>
  )
}
