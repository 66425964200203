import React from "react"
import "./css/about.css"
import { Link } from "react-router"
import { EditCMSWrapper } from "../../reusable components/EditCMS/EditCMSWrapper"
import TeamsComponent from "./components/TeamsComponent"
import QualityComponent from "./components/QualityComponent"
import { translateCustom } from "../../reusable components/i18n/i18n"

export const AboutUs = () => {
  return (
    <>
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="center_o1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">{translateCustom("About Us")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="prosp">
        <div className="container">
          <div className="row">
            <div className="Prosp_1 text-center clearfix">
              <div className="col-sm-12">
                <h4 className="mgt">
                  <EditCMSWrapper dataKey="aboutUs1" />
                </h4>
                <h1 className="bold">
                  <span className="col_1">
                    {" "}
                    <EditCMSWrapper dataKey="aboutUs2" />
                  </span>{" "}
                  <span>
                    {" "}
                    <EditCMSWrapper dataKey="aboutUs3" />
                  </span>
                </h1>
                <p>
                  <EditCMSWrapper dataKey="aboutUs4" />
                </p>
              </div>
            </div>
            <div className="Prosp_2 clearfix">
              <div className="col-sm-4">
                <div className="Prosp_2i clearfix">
                  <EditCMSWrapper
                    imageProps={{ className: "iw", height: 300 }}
                    type="image"
                    dataKey="aboutUs5"
                  />
                  <h5>
                    <EditCMSWrapper dataKey="aboutUs6" />
                  </h5>
                  <h2>
                    <EditCMSWrapper dataKey="aboutUs7" />
                  </h2>
                  <p>
                    <EditCMSWrapper dataKey="aboutUs8" />
                  </p>
                  {/* <h5 className="bold">
                    <Link to="/">Find out more</Link>
                  </h5> */}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="Prosp_2i clearfix">
                  <EditCMSWrapper
                    imageProps={{ className: "iw", height: 300 }}
                    type="image"
                    dataKey="aboutUs12"
                  />
                  <h5>
                    <EditCMSWrapper dataKey="aboutUs9" />
                  </h5>
                  <h2>
                    <EditCMSWrapper dataKey="aboutUs10" />
                  </h2>
                  <p>
                    <EditCMSWrapper dataKey="aboutUs11" />
                  </p>
                  {/* <h5 className="bold">
                    <Link to="/">Find out more</Link>
                  </h5> */}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="Prosp_2i clearfix">
                  <EditCMSWrapper
                    imageProps={{ className: "iw", height: 300 }}
                    type="image"
                    dataKey="aboutUs13"
                  />
                  <h5>
                    <EditCMSWrapper dataKey="aboutUs14" />
                  </h5>
                  <h2>
                    <EditCMSWrapper dataKey="aboutUs15" />
                  </h2>
                  <p>
                    <EditCMSWrapper dataKey="aboutUs16" />
                  </p>
                  {/* <h5 className="bold">
                    <Link to="/">Find out more</Link>
                  </h5> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <QualityComponent />
      <TeamsComponent />

      {/* <section id="quality">
        <div className="quality_m clearfix">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="service_h1 service_h1o text-center">
                  <h2 className="col">
                    <EditCMSWrapper dataKey="aboutUs17" />
                  </h2>
                  <span className="block_icon">
                    <i className="fa fa-leaf" />
                  </span>
                  <p className="col_3">
                    <EditCMSWrapper dataKey="aboutUs18" />
                  </p>
                </div>
              </div>
              <div className="col-sm-12 space_all">
                <div className="quality_1">
                  <div className="col-sm-2">
                    <div className="quality_1i text-center">
                      <span>
                        <i className="fa fa-leaf" />
                      </span>
                      <h4 className="mgt">
                        <EditCMSWrapper dataKey="aboutUs19" />
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="quality_1i text-center">
                      <span>
                        <i className="fa fa-tree" />
                      </span>
                      <h4 className="mgt">
                        <EditCMSWrapper dataKey="aboutUs20" />
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="quality_1i text-center">
                      <span>
                        <i className="fa fa-male" />
                      </span>
                      <h4 className="mgt">
                        <EditCMSWrapper dataKey="aboutUs21" />
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="quality_1i text-center">
                      <span>
                        <i className="fa fa-female" />
                      </span>
                      <h4 className="mgt">
                        <EditCMSWrapper dataKey="aboutUs22" />
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="quality_1i text-center">
                      <span>
                        <i className="fa fa-truck" />
                      </span>
                      <h4 className="mgt">
                        <EditCMSWrapper dataKey="aboutUs23" />
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="quality_1i text-center">
                      <span>
                        <i className="fa fa-phone" />
                      </span>
                      <h4 className="mgt">
                        <EditCMSWrapper dataKey="aboutUs24" />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section id="plan">
        <div className="container">
          <div className="row">
            <div className="plan_1 clearfix">
              <div className="col-sm-4">
                <div className="plan_1i clearfix">
                  <h2 className="bold mgt">
                    <span className="col_1">You'll Love Our</span>
                    <br />
                    <span className="col_2">Business Solutions</span>
                  </h2>
                  <p>
                    Quickly aggregate B2B users and worldwide potentialities.
                    Progressively plagiarize resource-leveling e-commerce
                    through resource-leveling core competencies.
                  </p>
                  <ul>
                    <li>
                      <i className="fa fa-star col_1" /> Great Prospect To
                      Thrive
                    </li>
                    <li>
                      <i className="fa fa-star col_1" /> Modern Solutions &amp;
                      Advices
                    </li>
                    <li>
                      <i className="fa fa-star col_1" /> In Trend With The Times
                    </li>
                    <li>
                      <i className="fa fa-star col_1" /> Inline With Your
                      Requirements
                    </li>
                    <li>
                      <i className="fa fa-star col_1" /> Easy To Adapt Your
                      Business To
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="plan_1i1 clearfix">
                  <span>
                    <i className="fa fa-rupee col_2" />
                  </span>
                  <div className="plan_1i1i clearfix">
                    <h1 className="mgt col_1">
                      <i className="fa fa-rupee" /> 199
                    </h1>
                    <h5>Upgrade Package</h5>
                    <h3 className="bold">Business Optimization</h3>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-check col_1" /> Busines Checkup
                    </li>
                    <li>
                      <i className="fa fa-check col_1" /> Proposing Changes
                    </li>
                    <li>
                      <i className="fa fa-check col_1" /> Marketing plan
                    </li>
                    <li>
                      <i className="fa fa-check col_1" /> Social Media plan
                    </li>
                  </ul>
                  <h4 className="bold">
                    <Link to="/">Purchase now</Link>
                  </h4>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="plan_1i1 plan_1i1_o clearfix">
                  <span>
                    <i className="fa fa-rupee col" />
                  </span>
                  <div className="plan_1i1i clearfix">
                    <h1 className="mgt col">
                      <i className="fa fa-rupee" /> 259
                    </h1>
                    <h5 className="col">Full Package</h5>
                    <h3 className="bold col">
                      Complete <br /> Business Plan
                    </h3>
                  </div>
                  <ul>
                    <li className="col">
                      <i className="fa fa-check col" /> Busines Checkup
                    </li>
                    <li className="col">
                      <i className="fa fa-check col" /> Proposing Changes
                    </li>
                    <li className="col">
                      <i className="fa fa-check col" /> Marketing plan
                    </li>
                    <li className="col">
                      <i className="fa fa-check col" /> Social Media plan
                    </li>
                  </ul>
                  <h4 className="bold">
                    <Link className="col" to="#">
                      Purchase now
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section id="team_h">
        <div className="project_m clearfix">
          <div className="container">
            <div className="row">
              <div className="service_h1 clearfix text-center">
                <div className="col-sm-12">
                  <h2 className="col">Our Team</h2>
                  <span className="block_icon">
                    <i className="fa fa-leaf" />
                  </span>
                  <p className="col_3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry has been the industry's
                    <br /> standard dummy text ever since the been when an
                    unknown printer.
                  </p>
                </div>
              </div>
              <div className="team_h1 clearfix">
                <div className="col-sm-6">
                  <div className="team_h1i clearfix">
                    <div className="col-sm-5 space_all">
                      <div className="team_h1il clearfix">
                        <img
                          src="/images/frontPageImages/6.jpg"
                          height={320}
                          className="iw"
                          alt="abc"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 space_all">
                      <div className="team_h1ir clearfix">
                        <h5 className="mgt">Eget Nulla</h5>
                        <h6 className="col_1">Creative Director</h6>
                        <p>
                          <Link to="/">info@gmail.com</Link>
                        </p>
                        <p>
                          Bibendum lacus gralda placerat consequat sivamus quis
                          hendrerit mauris. The great explorer of the truth, the
                          master-builder of human happiness.{" "}
                        </p>
                        <ul className="social-network social-circle">
                          <li>
                            <Link to="#" className="icoRss" title="Rss">
                              <i className="fa fa-rss" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoFacebook"
                              title="Facebook"
                            >
                              <i className="fa fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoTwitter" title="Twitter">
                              <i className="fa fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoGoogle" title="Google +">
                              <i className="fa fa-google-plus" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoLinkedin"
                              title="Linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="team_h1i clearfix">
                    <div className="col-sm-5 space_all">
                      <div className="team_h1il clearfix">
                        <img
                          src="/images/frontPageImages/7.jpg"
                          height={320}
                          className="iw"
                          alt="abc"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 space_all">
                      <div className="team_h1ir clearfix">
                        <h5 className="mgt">Quis Nulla</h5>
                        <h6 className="col_1">Creative Director</h6>
                        <p>
                          <Link to="/">info@gmail.com</Link>
                        </p>
                        <p>
                          Bibendum lacus gralda placerat consequat sivamus quis
                          hendrerit mauris. The great explorer of the truth, the
                          master-builder of human happiness.{" "}
                        </p>
                        <ul className="social-network social-circle">
                          <li>
                            <Link to="#" className="icoRss" title="Rss">
                              <i className="fa fa-rss" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoFacebook"
                              title="Facebook"
                            >
                              <i className="fa fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoTwitter" title="Twitter">
                              <i className="fa fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoGoogle" title="Google +">
                              <i className="fa fa-google-plus" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoLinkedin"
                              title="Linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team_h1 clearfix">
                <div className="col-sm-6">
                  <div className="team_h1i clearfix">
                    <div className="col-sm-5 space_all">
                      <div className="team_h1il clearfix">
                        <img
                          src="/images/frontPageImages/8.jpg"
                          height={320}
                          className="iw"
                          alt="abc"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 space_all">
                      <div className="team_h1ir clearfix">
                        <h5 className="mgt">Semp Port</h5>
                        <h6 className="col_1">Creative Director</h6>
                        <p>
                          <Link to="/">info@gmail.com</Link>
                        </p>
                        <p>
                          Bibendum lacus gralda placerat consequat sivamus quis
                          hendrerit mauris. The great explorer of the truth, the
                          master-builder of human happiness.{" "}
                        </p>
                        <ul className="social-network social-circle">
                          <li>
                            <Link to="#" className="icoRss" title="Rss">
                              <i className="fa fa-rss" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoFacebook"
                              title="Facebook"
                            >
                              <i className="fa fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoTwitter" title="Twitter">
                              <i className="fa fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoGoogle" title="Google +">
                              <i className="fa fa-google-plus" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoLinkedin"
                              title="Linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="team_h1i clearfix">
                    <div className="col-sm-5 space_all">
                      <div className="team_h1il clearfix">
                        <img
                          src="/images/frontPageImages/9.jpg"
                          height={320}
                          className="iw"
                          alt="abc"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 space_all">
                      <div className="team_h1ir clearfix">
                        <h5 className="mgt">Ante Dapibus</h5>
                        <h6 className="col_1">Creative Director</h6>
                        <p>
                          <Link to="/">info@gmail.com</Link>
                        </p>
                        <p>
                          Bibendum lacus gralda placerat consequat sivamus quis
                          hendrerit mauris. The great explorer of the truth, the
                          master-builder of human happiness.{" "}
                        </p>
                        <ul className="social-network social-circle">
                          <li>
                            <Link to="#" className="icoRss" title="Rss">
                              <i className="fa fa-rss" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoFacebook"
                              title="Facebook"
                            >
                              <i className="fa fa-facebook" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoTwitter" title="Twitter">
                              <i className="fa fa-twitter" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="icoGoogle" title="Google +">
                              <i className="fa fa-google-plus" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="icoLinkedin"
                              title="Linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}
