import { Box } from "@mui/material"
import React from "react"
import HumidityDrop from "../../reusable components/HumidityDrop/HumidityDrop"
import ReactSpeedometerComponent from "../../reusable components/ReactSpeedmeter/ReactSpeedometerComponent"
import ThermometerComponent from "../../reusable components/Thermometer/ThermometerComponent"

import {
  getHighestValuesByMonth,
  getLastRecordsFor30Days,
} from "../../api/data"
import { getDynamicContext } from "../../context/AppContext"
import ChartTemplate from "../Dashboard/components/ChartTemplate"

const series = [
  { dataKey: "temperature", label: "Temperature" },
  { dataKey: "humidity", label: "Humidity" },
  { dataKey: "conductivity", label: "Conductivity" },
]
const lineChartSeries = [
  {
    id: "temperature",
    dataKey: "temperature",
    label: "Temperature",
    showMark: false,
    curve: "linear",
    area: true,
    stackOrder: "ascending",
    color: "#52b202",
  },
  {
    id: "humidity",
    dataKey: "humidity",
    label: "Humidity",
    showMark: false,
    curve: "linear",
    area: true,
    stackOrder: "ascending",
    color: "#d32f2f",
  },
  {
    id: "conductivity",
    dataKey: "conductivity",
    label: "Conductivity",
    showMark: false,
    curve: "linear",
    stackOrder: "ascending",
    color: "#f57c00",
    area: true,
  },
]

const columns = [
  {
    field: "temperature",
    headerName: "Temperature",
    flex: 0.5,
    minWidth: 80,
  },
  {
    field: "humidity",
    headerName: "Humidity",
    flex: 0.5,
    minWidth: 80,
  },
  {
    field: "conductivity",
    headerName: "Conductivity",
    flex: 0.5,
    minWidth: 80,
  },
  // {
  //   field: "percentage",
  //   headerName: "Percentage",
  //   headerAlign: "right",
  //   align: "right",
  //   flex: 1,
  //   minWidth: 80,
  // },
  {
    field: "intelligence",
    headerName: "Value",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "result",
    headerName: "Result",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "timestamp",
    headerName: "Time",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
  // {
  //   field: 'conversions',
  //   headerName: 'Daily Conversions',
  //   flex: 1,
  //   minWidth: 150,
  //   renderCell: renderSparklineCell,
  // },
]

const colorPalette = ["#52b202", "#d32f2f", "#f57c00"]

const gradients = [
  { id: "temperature", color: "#52b202" },
  { id: "humidity", color: "#d32f2f" },
  { id: "conductivity", color: "#f57c00" },
]

function HT_EC() {
  const { HT_EC_Data } = getDynamicContext()
  const lastRecordsFor30Days = getLastRecordsFor30Days(HT_EC_Data)
  const lastRecord = HT_EC_Data.at(-1)

  const { conductivity, temperature, humidity } = lastRecord.interpretation

  const curveData = [
    {
      title: `Temperature (${temperature})`,
      value: "14",
      interval: "Last 30 days",
      trend: "up",
      data: lastRecordsFor30Days.map((record) => record.temperature).slice(-30),
    },
    {
      title: `Humidity (${humidity})`,
      value: "325",
      interval: "Last 30 days",
      trend: "down",
      data: lastRecordsFor30Days.map((record) => record.humidity).slice(-30),
    },
    {
      title: `Conductivity (${conductivity})`,
      value: "200",
      interval: "Last 30 days",
      trend: "neutral",
      data: lastRecordsFor30Days
        .map((record) => record.conductivity)
        .slice(-30),
    },
  ]
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          marginTop: 3,
        }}
      >
        {/* Humidity Drop Card */}
        <HumidityDrop
          title={`Humidity (${humidity})`}
          value={lastRecord.humidity}
        />

        {/* temperature Monitor Card */}
        <ThermometerComponent
          title={`Temperature (${temperature})`}
          theme="light"
          value={lastRecord.temperature}
          max="50"
          steps="5"
          format="°C"
          size="large"
          height="300"
        />

        {/* Electrical Conductivity Card */}
        <ReactSpeedometerComponent
          title={`Electrical Conductivity (${conductivity})`}
          maxValue={10}
          minValue={0}
          height={190}
          width={290}
          value={lastRecord.conductivity}
          needleTransition="easeQuadIn"
          needleTransitionDuration={1000}
          needleColor="red"
          startColor="green"
          segments={10}
          endColor="blue"
        />
      </Box>

      <ChartTemplate
        chartTitle={"Humidity, Temperature, and Electrical Conductivity"}
        rows={HT_EC_Data}
        columns={columns}
        curveData={curveData}
        series={series}
        colorPalette={colorPalette}
        lineChartSeries={lineChartSeries}
        gradients={gradients}
        pageViewBarChartDataset={getHighestValuesByMonth(HT_EC_Data, [
          "temperature",
          "humidity",
          "conductivity",
        ])}
      />
    </div>
  )
}

export default HT_EC
