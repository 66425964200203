import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { useLocation, Link } from "react-router-dom";
import TypographyComponent from "../../../reusable components/i18n/TypographyComponent";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: theme.palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: "center",
  },
}));

export default function NavbarBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <StyledBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextRoundedIcon fontSize="small" />}
    >
      {/* <Typography variant="body1" component={Link} to="/" sx={{ textDecoration: "none" }}>
        Dashboard
      </Typography> */}
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        const formattedValue = value
          .split("_")
          .map((word, i) => (i === 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
          .join(" ");

        return isLast ? (
          <TypographyComponent
            key={to}
            variant="body1"
            sx={{ color: "text.primary", fontWeight: 600 }}
          >
            {formattedValue}
          </TypographyComponent>
        ) : (
          <TypographyComponent
            key={to}
            variant="body1"
            component={Link}
            to={to}
            sx={{ textDecoration: "none", color: "inherit" }}
          >
            {formattedValue}
          </TypographyComponent>
        );
      })}
    </StyledBreadcrumbs>
  );
}
