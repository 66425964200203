import React from "react"
import AddIcon from "@mui/icons-material/Add"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import SaveIcon from "@mui/icons-material/Save"
import { Grid2 } from "@mui/material"
import Paper from "@mui/material/Paper"
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent"
import SelectTextField from "../SelectTextField/SelectTextField"
import { ButtonComponent } from "../ButtonComponent/ButtonComponent"
import BuildIcon from "@mui/icons-material/Build" // Import the icon

function ReusableForm({
  columns,
  formState,
  setFormState,
  handleSubmit,
  handleFillTestData,
  CRUD,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleReset = () => {
    setFormState(
      columns.reduce((acc, column) => {
        acc[column.field] = ""
        return acc
      }, {})
    )
  }

  const onSubmit = (e) => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <form onSubmit={onSubmit}>
        <Grid2 container spacing={2}>
          {columns.map((column) => (
            <Grid2 size={{ xs: 12, sm: 6 }} key={column.field}>
              {column.type === "select" ? (
                <SelectTextField
                  {...column}
                  onChange={handleInputChange}
                  value={formState[column.field]}
                />
              ) : (
                <TextFieldComponent
                  {...column}
                  value={formState[column.field]}
                  onChange={handleInputChange}
                />
              )}
            </Grid2>
          ))}

          {/* Submit Button */}
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <ButtonComponent
              type="submit"
              bgColor="blue"
              tooltipText={
                CRUD ? (formState.id ? "Update Row" : "Add Row") : "Confirm"
              }
              Icon={formState.id ? <SaveIcon /> : <AddIcon />}
              onClick={onSubmit}
              text={
                CRUD ? (formState.id ? "Update Row" : "Add Row") : "Confirm"
              }
              fullWidth
            />
          </Grid2>

          {/* Reset Button */}
          {CRUD && (
            <Grid2 size={{ xs: 12, sm: 3 }}>
              <ButtonComponent
                bgColor="orange"
                tooltipText="Reset Form"
                Icon={<RestartAltIcon />}
                onClick={handleReset}
                text="Reset"
                fullWidth
              />
            </Grid2>
          )}

          {/* Fill Test Data Button */}
          {handleFillTestData && (
            <Grid2 size={{ xs: 12, sm: 3 }}>
              <ButtonComponent
                bgColor="purple"
                tooltipText="Fill with test data"
                onClick={handleFillTestData}
                text="Fill Test Data"
                Icon={<BuildIcon />}
                fullWidth
              />
            </Grid2>
          )}
        </Grid2>
      </form>
    </Paper>
  )
}

export default ReusableForm
