{
  /* //! -------------- Blue ------------------ */
}
export const darkBlueGradient = "linear-gradient(to right, #00395d, #8f8f8c)"
export const darkBlueGradient2 =
  "linear-gradient(rgba(34, 42, 69, 0.96), rgba(34, 42, 69, 0.96))"
export const darkBlueGradientLight =
  "linear-gradient(rgba(34, 42, 69, 0.90), rgba(34, 42, 69, 0.90))"
export const blueGradient = "linear-gradient(135deg, #00b0ff, #0063b5)"

{
  /* //! -------------- Green ----------------- */
}
export const greenGradient = "linear-gradient(to top, #0ba360 0%, #3cba92 100%)"
export const lightGreenGradient = "linear-gradient(to right, #8CC63F, #B1D96B)"
export const darkGreenGradient = "linear-gradient(to right, #006400, #003300)"

{
  /* //! --------------- Red ------------------ */
}
export const redGradient = "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
export const orangeGradient = "linear-gradient(120deg, #ff6600 0%, #ff9933 100%)"
{
  /* //! ---------- White & Black ------------- */
}
export const grayGradient = "linear-gradient(to right, #888, #ddd)"

{
  /* //! ------------- Purple ----------------- */
}
export const purpleRedGradient = "linear-gradient(120deg, #a6c0fe 0%, #f68084 100%)"
export const purpleGradient = "linear-gradient(to bottom, #8e44ad, #5d2c91)"

