import i18n from "i18next"
import { initReactI18next, useTranslation } from "react-i18next"
import HttpApi from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

import enTranslations from "./locales/en.json"
import frTranslations from "./locales/fr.json"
import arTranslations from "./locales/ar.json"

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar", "fr"],
    fallbackLng: "en",
    debug: false, // Disable debug mode to remove warnings
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: enTranslations,
      },
      fr: {
        translation: frTranslations,
      },
      ar: {
        translation: arTranslations,
      },
    },
    saveMissing: true, // Allow missing translations to be logged
  })

export default i18n

const missingKeys = new Set(
  JSON.parse(localStorage.getItem("missingKeys")) || []
)

export const translateCustom = (text) => {
  const { t } = useTranslation()

  if (!text || typeof text !== "string") {
    return text
  }

  const trimmedText = text.trim()
  const translationKey = trimmedText.toLowerCase()
  const translation = t(translationKey)

  if (!translation || translation === translationKey) {
    if (!missingKeys.has(translationKey)) {
      console.log(translationKey + "| Has been added to the missing key list")

      missingKeys.add(translationKey)
      localStorage.setItem(
        "missingKeys",
        JSON.stringify(Array.from(missingKeys))
      )
    }
    return trimmedText
  }
  return translation
}

export const TranslateCustomNonHook = (text) => {
  if (!text || typeof text !== "string") {
    return "Missing Translation"
  }

  const trimmedText = text.trim()
  const translationKey = trimmedText.toLowerCase()
  const translation = i18n.t(translationKey)

  if (!translation || translation === translationKey) {
    return trimmedText
  }

  return translation
}

export const translateColumns = (columns) =>
  columns.map((column) => ({
    ...column,
    headerName: translateCustom(column.headerName),
  }))
