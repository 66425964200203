import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import MuiCard from "@mui/material/Card"
import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ButtonComponent } from "../ButtonComponent/ButtonComponent"
import AppTheme from "./theme/AppTheme"
import ColorModeSelect from "./theme/ColorModeSelect"
import TypographyComponent from "../i18n/TypographyComponent"
import { LanguageSwitcher } from "../i18n/LanguageSwitcher"
import { translateCustom } from "../i18n/i18n"
import { useTranslation } from "react-i18next"

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}))

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}))

export const Login = (props: {
  disableCustomTheme?: boolean
  siteName: string
  logo: any
  loggingFunction: (data: object, keepLogin: boolean) => void
}) => {
  // const { t } = useTranslation();

  const [searchParams] = useSearchParams()
  const qrCode = searchParams.get("qrCode")

  useEffect(() => {
    if (qrCode) props.loggingFunction({ qrCode }, true)
  }, [])

  const navigate = useNavigate()
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
  const [rememberMe, setRememberMe] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev)
  }

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberMe(event.target.checked)
  }

  const validateFields = (email: string, password: string) => {
    let isValid = true

    // Email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true)
      // // TODO TRANSLATION
      setEmailErrorMessage("Please enter a valid email address.")
      isValid = false
    } else {
      setEmailError(false)
      setEmailErrorMessage("")
    }

    // Password validation
    if (!password || password.length < 6) {
      setPasswordError(true)
      // // TODO TRANSLATION
      setPasswordErrorMessage("Password must be at least 6 characters long.")
      isValid = false
    } else {
      setPasswordError(false)
      setPasswordErrorMessage("")
    }

    return isValid
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get("email") as string
    const password = data.get("password") as string

    if (validateFields(email, password)) {
      props.loggingFunction({ email, password }, rememberMe)
    }
  }

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%", flexDirection: { sm: "row" } }}
        >
          <ButtonComponent
            Icon={<ArrowBackIcon />}
            tooltipText={translateCustom("Go back")}
            onClick={() => navigate("/")}
          />
          <LanguageSwitcher />
          <ColorModeSelect />
        </Stack>
        <Card variant="outlined">
          <TypographyComponent component="h1" variant="h4">
            {translateCustom("Sign in")}
          </TypographyComponent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">{translateCustom("Email")}</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder={"your@email.com"}
                autoComplete="email"
                autoFocus
                required
                fullWidth
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">
                {translateCustom("Password")}
              </FormLabel>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder={"••••••"}
                type={passwordVisible ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
              }
              label={translateCustom("Remember me")}
            />
            <Link
              href="./forgot-password"
              variant="body2"
              sx={{ alignSelf: "flex-end" }}
            >
              {translateCustom("Forgot password?")}
            </Link>
            <Button type="submit" fullWidth variant="contained">
              {translateCustom("Sign in")}
            </Button>
          </Box>
        </Card>
      </SignInContainer>
    </AppTheme>
  )
}
