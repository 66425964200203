import React from "react"
import "./css/contact.css"
import "./css/blog.css"
import { Link } from "react-router"
import { EditCMSWrapper } from "../../reusable components/EditCMS/EditCMSWrapper"
import { translateCustom } from "../../reusable components/i18n/i18n"

export const Contact = () => {
  return (
    <>
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="center_o1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">{translateCustom("Contact Us")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="contact_2 clearfix">
              <div className="col-sm-6">
                <div className="contact_2l clearfix">
                  <h1 className="mgt">
                    {" "}
                    <EditCMSWrapper dataKey="contact1" />
                  </h1>
                  <hr className="hr_1 mgl" />
                  <h4 className="mgt italic normal">
                    <EditCMSWrapper dataKey="contact2" />
                  </h4>
                  <h4 className="italic normal">
                    <EditCMSWrapper dataKey="contact3" />
                  </h4>
                  <h4 className="italic normal">
                    <EditCMSWrapper dataKey="contact4" />
                  </h4>
                  <h4 className="italic normal">
                    <EditCMSWrapper dataKey="contact5" />
                  </h4>
                  <ul className="social-network social-circle">
                    <li>
                      <Link to="#" className="icoRss" title="Rss">
                        <i className="fa fa-rss" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="icoFacebook" title="Facebook">
                        <i className="fa fa-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="icoTwitter" title="Twitter">
                        <i className="fa fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="icoGoogle" title="Google +">
                        <i className="fa fa-google-plus" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="icoLinkedin" title="Linkedin">
                        <i className="fa fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="contact_2r clearfix">
                  <h1 className="mgt">
                    <EditCMSWrapper dataKey="contact6" />
                  </h1>
                  <hr className="hr_1 mgl" />
                  <div className="blog1l5 cleafix">
                    <input
                      placeholder="Your Name"
                      className="form-control"
                      type="text"
                    />
                    <input
                      placeholder="Your Email"
                      className="form-control"
                      type="text"
                    />
                    <input
                      placeholder="Your Phone"
                      className="form-control"
                      type="text"
                    />
                    <textarea
                      placeholder="Your Message"
                      className="form-control form_1"
                      defaultValue={""}
                    />
                    <h5 className="normal">
                      <Link className="button" to="#">
                        Submit
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact_1 clearfix">
              <div className="col-sm-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12949.906050664411!2d10.8054417!3d35.7636695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130213694b9e260f%3A0xfd85b303fbcc5670!2sFacult%C3%A9%20des%20Sciences%20de%20Monastir!5e0!3m2!1sen!2stn!4v1733847595596!5m2!1sen!2stn"
                  style={{ border: 0, height: 450, width: "100%" }}
                  allowFullScreen=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
