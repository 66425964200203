import { StyledEngineProvider } from "@mui/material/styles"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import App from "./App"
import { AppProvider, getDynamicContext } from "./context/AppContext"

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <StyledEngineProvider injectFirst>
      <AppProvider>
        <BrowserRouter >
          <ToastContainerWrapper />
          <App />
        </BrowserRouter>
      </AppProvider>
  </StyledEngineProvider>
)

function ToastContainerWrapper() {
  const { themeMode } = getDynamicContext() 

  return (
    <ToastContainer
      theme={themeMode} 
    />
  )
}
