import Box from "@mui/material/Box"
import Grid2 from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import dayjs from "dayjs"
import React, { useMemo, useState } from "react"
import DateTimeRangePicker from "../../../reusable components/DateTimeRangePicker/DateTimeRangePicker"
import CustomizedDataGrid from "./CustomizedDataGrid"
import PageViewsBarChart from "./PageViewsBarChart"
import SessionsChart from "./SessionsChart"
import StatCard from "./StatCard"
import TypographyComponent from "../../../reusable components/i18n/TypographyComponent"

export default function ChartTemplate({
  children,
  curveData,
  chartTitle,
  columns,
  rows,
  series,
  colorPalette,
  lineChartSeries,
  gradients,
  pageViewBarChartDataset,
}) {
  const [startDateInterval, setStartDateInterval] = useState(
    dayjs().subtract(30, "day")
  )
  const [endDateInterval, setEndDateInterval] = useState(dayjs())
  const onChangeCallback = (startDate, endDate) => {
    setStartDateInterval(dayjs(startDate))
    setEndDateInterval(dayjs(endDate))
  }

  const formatDateRange = (start, end) => {
    return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")}`
  }

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      const rowDate = dayjs(row.timestamp)
      return rowDate.isBetween(startDateInterval, endDateInterval, "day", "[]")
    })
  }, [rows, startDateInterval, endDateInterval])
  console.log(filteredRows)

  // console.log(getDaysInInterval(startDateInterval, endDateInterval))
  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* Date Picker and Overview Title */}
      <TypographyComponent component="h2" variant="h6" sx={{ mb: 2 }}>
        Overview
      </TypographyComponent>
      <Grid2
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {/* Cards */}
        {curveData.map((card, index) => (
          <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid2>
        ))}
        <Grid2 size={{ xs: 12, md: 12 }}>
          <PageViewsBarChart
            dataset={pageViewBarChartDataset}
            title={chartTitle + " highest values by month"}
            series={series}
            colorPalette={colorPalette}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <DateTimeRangePicker onChangeCallback={onChangeCallback} />
        </Grid2>

        {/* Charts */}
        <Grid2 size={{ xs: 12, md: 12 }}>
          <SessionsChart
            dataset={filteredRows}
            series={lineChartSeries}
            colorPalette={colorPalette}
            title={chartTitle + " over time"}
            xAxis={[
              {
                scaleType: "point",
                tickInterval: (index, i) => (i + 1) % 5 === 0,
                valueFormatter: (value) => dayjs(value).format("MMM D"),
                dataKey: "timestamp",
              },
            ]}
            subTitle={formatDateRange(startDateInterval, endDateInterval)}
            gradients={gradients}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, lg: 12 }}>
          <CustomizedDataGrid
            columns={columns}
            rows={filteredRows}
            getRowId={(row) => row.ID}
          />
        </Grid2>

        {/* Additional Components */}
        {children}
      </Grid2>
    </Box>
  )
}
