import { Box } from "@mui/material"
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge"
import React from "react"

import { CardComponent } from "ska-react-library"
import ChartTemplate from "../Dashboard/components/ChartTemplate"
import {
  getHighestValuesByMonth,
  getLastRecordsFor30Days,
} from "../../api/data"
import { getDynamicContext } from "../../context/AppContext"

const series = [
  { dataKey: "nitrogen", label: "nitrogen" },
  { dataKey: "phosphorus", label: "Phosphore" },
  { dataKey: "potassium", label: "Potassium" },
]
const lineChartSeries = [
  {
    id: "nitrogen",
    dataKey: "nitrogen",
    label: "nitrogen",
    showMark: false,
    curve: "linear",
    area: true,
    stackOrder: "ascending",
    color: "#52b202",
  },
  {
    id: "phosphorus",
    dataKey: "phosphorus",
    label: "Phosphore",
    showMark: false,
    curve: "linear",
    area: true,
    stackOrder: "ascending",
    color: "#d32f2f",
  },
  {
    id: "potassium",
    dataKey: "potassium",
    label: "Potassium",
    showMark: false,
    curve: "linear",
    stackOrder: "ascending",
    color: "#f57c00",
    area: true,
  },
]

const columns = [
  {
    field: "nitrogen",
    headerName: "nitrogen",
    flex: 0.5,
    minWidth: 80,
  },
  {
    field: "phosphorus",
    headerName: "Phosphore",
    flex: 0.5,
    minWidth: 80,
  },
  {
    field: "potassium",
    headerName: "Potassium",
    flex: 0.5,
    minWidth: 80,
  },
  // {
  //   field: "percentage",
  //   headerName: "Percentage",
  //   headerAlign: "right",
  //   align: "right",
  //   flex: 1,
  //   minWidth: 80,
  // },
  // {
  //   field: "intelligence",
  //   headerName: "Value",
  //   headerAlign: "right",
  //   align: "right",
  //   flex: 1,
  //   minWidth: 100,
  // },
  {
    field: "result",
    headerName: "Result",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "timestamp",
    headerName: "Time",
    headerAlign: "right",
    align: "right",
    flex: 1,
    minWidth: 100,
  },
  // {
  //   field: 'conversions',
  //   headerName: 'Daily Conversions',
  //   flex: 1,
  //   minWidth: 150,
  //   renderCell: renderSparklineCell,
  // },
]

const colorPalette = ["#52b202", "#d32f2f", "#f57c00"]

const gradients = [
  { id: "nitrogen", color: "#52b202" },
  { id: "phosphorus", color: "#d32f2f" },
  { id: "potassium", color: "#f57c00" },
]

const gaugeStyles = (color) => ({
  [`& .${gaugeClasses.valueText}`]: {
    fontSize: 28,
    fill: color,
  },
  [`& .${gaugeClasses.valueArc}`]: {
    fill: color,
  },
  [`& .${gaugeClasses.referenceArc}`]: {
    fill: "#e0e0e0",
  },
})

const NPK_Page = () => {
  const { NPK_Data } = getDynamicContext()
  const lastRecord = NPK_Data.at(-1)

  const { nitrogen, phosphorus, potassium } = lastRecord.interpretation

  const nutrients = [
    {
      label: `Azote (N) (${nitrogen})`,
      value: lastRecord.nitrogen,
      color: "#52b202",
    },
    {
      label: `Phosphorus (P) (${phosphorus})`,
      value: lastRecord.phosphorus,
      color: "#d32f2f",
    },
    {
      label: `Potassium (K) (${potassium})`,
      value: lastRecord.potassium,
      color: "#f57c00",
    },
  ]

  const lastRecordsFor30Days = getLastRecordsFor30Days(NPK_Data)

  const curveData = [
    {
      title: "Azote (N)",
      value: "14",
      interval: "Last 30 days",
      trend: "up",
      data: lastRecordsFor30Days.map((record) => record.nitrogen).slice(-30),
    },
    {
      title: "Phosphorus (P)",
      value: "325",
      interval: "Last 30 days",
      trend: "down",
      data: lastRecordsFor30Days.map((record) => record.phosphorus).slice(-30),
    },
    {
      title: "Potassium (K)",
      value: "200",
      interval: "Last 30 days",
      trend: "neutral",
      data: lastRecordsFor30Days.map((record) => record.potassium).slice(-30),
    },
  ]

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          marginTop: 3,
        }}
      >
        {nutrients.map((nutrient, index) => (
          <CardComponent
            disableAnimation={false}
            key={index}
            sx={{ maxWidth: 400 }}
            title={nutrient.label}
          >
            <Gauge
              width={200}
              height={200}
              value={nutrient.value}
              thickness={20}
              sx={gaugeStyles(nutrient.color)}
              label={`${nutrient.value}%`}
              min={0}
              max={100}
            />
          </CardComponent>
        ))}
      </Box>
      <ChartTemplate
        rows={NPK_Data}
        columns={columns}
        curveData={curveData}
        chartTitle={"Azote(N), Phosphorus(P), Potassium(K)"}
        series={series}
        colorPalette={colorPalette}
        lineChartSeries={lineChartSeries}
        gradients={gradients}
        pageViewBarChartDataset={getHighestValuesByMonth(NPK_Data, [
          "nitrogen",
          "phosphorus",
          "potassium",
        ])}
      />
    </>
  )
}

export default NPK_Page
