import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded"
import Badge from "@mui/material/Badge"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import Stack from "@mui/material/Stack"
import * as React from "react"
import { useState } from "react"
import ColorModeIconDropdown from "../theme/ColorModeIconDropdown"
import CustomDatePicker from "./CustomDatePicker"
import NavbarBreadcrumbs from "./NavbarBreadcrumbs"

import { LanguageSwitcher } from "../../../reusable components/i18n/LanguageSwitcher"
import Search from "./Search"
import { dashboardContext } from "../Dashboard"
import MuiList from "../../../ToSendToLibrary"

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const { notificationData } = React.useContext(dashboardContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: "none", md: "flex" },
        width: "calc(100% - 240px)",
        alignItems: { xs: "flex-start", md: "center" },
        justifyContent: "space-between",
        maxWidth: { sm: "100%", md: "1700px" },
        pt: 1.5,
        px: 1.5,

        position: "fixed",
        top: 0,
        zIndex: 1000,
        backgroundColor: "background.paper",
      }}
      spacing={2}
    >
      <NavbarBreadcrumbs />
      <Stack direction="row" sx={{ gap: 1 }}>
        <LanguageSwitcher />
        {/* <Search /> */}
        <CustomDatePicker />
        <IconButton onClick={handleClick}>
          <Badge badgeContent={notificationData.length} color="error">
            <NotificationsRoundedIcon />
          </Badge>
        </IconButton>
        <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
          <ClickAwayListener onClickAway={handleClose}>
            <MuiList data={notificationData} />
          </ClickAwayListener>
        </Popper>
        <ColorModeIconDropdown />
      </Stack>
    </Stack>
  )
}
