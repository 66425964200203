import React from "react"
import { EditCMSWrapper } from "../../../reusable components/EditCMS/EditCMSWrapper"

function TeamsComponent() {
  return (
    <section id="team_h">
      <div className="project_m clearfix">
        <div className="container">
          <div className="row">
            <div className="service_h1 clearfix text-center">
              <div className="col-sm-12">
                <h2 className="col">
                  <EditCMSWrapper dataKey="team1" />
                </h2>
                <span className="block_icon">
                  <i className="fa fa-leaf" />
                </span>
                <p className="col_3">
                  <EditCMSWrapper dataKey="team2" />
                </p>
              </div>
            </div>
            <div className="team_h1 clearfix">
              <div className="col-sm-6">
                <div className="team_h1i clearfix">
                  <div className="col-sm-5 space_all">
                    <div className="team_h1il clearfix">
                      <EditCMSWrapper
                        imageProps={{ className: "iw", height: 320 }}
                        type="image"
                        dataKey="team3"
                      />
                    </div>
                  </div>
                  <div className="col-sm-7 space_all">
                    <div className="team_h1ir clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="team4" />
                      </h5>
                      <h6 className="col_1">
                        <EditCMSWrapper dataKey="team5" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="team_h1i clearfix">
                  <div className="col-sm-5 space_all">
                    <div className="team_h1il clearfix">
                      <EditCMSWrapper
                        imageProps={{ className: "iw", height: 320 }}
                        type="image"
                        dataKey="team8"
                      />
                    </div>
                  </div>
                  <div className="col-sm-7 space_all">
                    <div className="team_h1ir clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="team9" />
                      </h5>
                      <h6 className="col_1">
                        <EditCMSWrapper dataKey="team10" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team_h1 clearfix">
              <div className="col-sm-6">
                <div className="team_h1i clearfix">
                  <div className="col-sm-5 space_all">
                    <div className="team_h1il clearfix">
                      <EditCMSWrapper
                        imageProps={{ className: "iw", height: 320 }}
                        type="image"
                        dataKey="team13"
                      />
                    </div>
                  </div>
                  <div className="col-sm-7 space_all">
                    <div className="team_h1ir clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="team14" />
                      </h5>
                      <h6 className="col_1">
                        <EditCMSWrapper dataKey="team15" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="team_h1i clearfix">
                  <div className="col-sm-5 space_all">
                    <div className="team_h1il clearfix">
                      <EditCMSWrapper
                        imageProps={{ className: "iw", height: 320 }}
                        type="image"
                        dataKey="team18"
                      />
                    </div>
                  </div>
                  <div className="col-sm-7 space_all">
                    <div className="team_h1ir clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="hero19" />
                      </h5>
                      <h6 className="col_1">
                        <EditCMSWrapper dataKey="hero20" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamsComponent
