import axios from "axios"
import { jwtDecode } from "jwt-decode"
import { toast } from "react-toastify"
import { navigateTo } from "../App"
import { setContext } from "../context/AppContext"
import { axiosAPI } from "./axiosConfig"
import { changePropertiesInArray, prepareData } from "./data"

/* //! -------------- Auth ------------------ */

export const handleLogin = async (formData, rememberMe) => {
  try {
    const response = await authLogin(formData)
    const loginToken = response.token

    // Decode the token and set logged user data
    const loggedUserData = { ...jwtDecode(loginToken), loginToken }
    setContext({ loggedUserData })

    if (rememberMe) {
      localStorage.setItem("loggedUserData", JSON.stringify(loggedUserData))
    }

    navigateTo("/dashboard")
  } catch (error) {
    console.error("Login Error:", error)
  }
}
export const logoutFunction = () => {
  localStorage.removeItem("loggedUserData")
  setContext({ loggedUserData: null })
}

export const authLogin = async (formData) =>
  axiosAPI.post("/auth/login", formData)

export const forgetPassword = (formData) =>
  axiosAPI.post(`/auth/forgot-password`, formData)

export const resetPassword = (formData) =>
  axiosAPI.post(`/auth/reset-password`, formData)

// export const checkPassword = (id, password) =>
//   axiosAPI.post(`/auth/checkPassword/${id}`, password);

export const updateSelfUser = async (formData) => {
  try {
    const { firstName, lastName, phoneNumber, newPassword, confirmPassword } =
      formData

    if ((newPassword || confirmPassword) && newPassword !== confirmPassword)
      return toast.error("Password is not the same")

    await axiosAPI.post(`/users/update-user`, {
      firstName,
      lastName,
      phoneNumber,
      newPassword,
    })

    logoutFunction()
  } catch (error) {
    console.log("ERROR IN updateSelfUser")
    console.log(error)
  }
}

/* #region Notification */
// export const fetchNotificationQuery = () =>
//   axiosAPI.post("/notification/fetchNotificationQuery");

// export const createNotification = (newNotification) =>
//   axiosAPI.post("/notification/", newNotification);

// export const updateNotification = (id, updatedNotification) =>
//   axiosAPI.patch(`/notification/${id}`, updatedNotification);

// export const deleteNotification = (id) => axiosAPI.delete(`/notification/${id}`);
// export const deleteAllNotification = (id) =>
//   axiosAPI.delete(`/notification/deleteAllNotification/delete`);
/* #endregion */

/* #region Web Data */
export const fetchWebData = () =>
  axiosAPI.get(`/web-data/get?pageName=agricultureFrontPage`)
export const saveWebData = (updatedData) =>
  axiosAPI.post("/web-data/save?pageName=agricultureFrontPage", updatedData)
/* #endregion */

/* #region General CRUD Functions */
export const createResource = async (endpoint, data) => {
  try {
    const response = await axiosAPI.post(endpoint, data)
    return response
  } catch (error) {
    console.error("Error creating resource:", error)
    throw error
  }
}

export const readResources = async (endpoint) => {
  try {
    const response = await axiosAPI.get(endpoint)
    return response
  } catch (error) {
    console.error("Error reading resources:", error)
    throw error
  }
}

export const updateResource = async (endpoint, data) => {
  try {
    const response = await axiosAPI.put(`${endpoint}/${data.id}`, data)
    return response
  } catch (error) {
    console.error("Error updating resource:", error)
    throw error
  }
}

export const deleteResource = async (endpoint, id) => {
  try {
    const response = await axiosAPI.delete(`${endpoint}/${id}`)
    return response
  } catch (error) {
    console.error("Error deleting resource:", error)
    throw error
  }
}

/* #endregion */

export const fetchNPK = async () =>
  prepareData(
    changePropertiesInArray(
      (
        await axios.get(
          `http://www.smartagri.tn/agriculture/get_data.php?sensor_npk`
        )
      ).data.data,
      {
        Nitrogen: "nitrogen",
        phosphore: "phosphorus",
      }
    ),
    ["nitrogen", "phosphorus", "potassium"]
  )

export const fetchLux = async () =>
  prepareData(
    changePropertiesInArray(
      (
        await axios.get(
          `http://www.smartagri.tn/agriculture/get_data.php?sensor_lux`
        )
      ).data.data,
      {
        Radiation: "intensity",
      }
    ),
    ["intensity"]
  )

export const fetchWind = async () =>
  prepareData(
    changePropertiesInArray(
      (
        await axios.get(
          `http://www.smartagri.tn/agriculture/get_data.php?sensor_wind`
        )
      ).data.data,
      { WindVitesse: "speed" }
    ),
    ["speed"]
  )

export const fetchSoil = async () =>
  prepareData(
    changePropertiesInArray(
      (
        await axios.get(
          `http://www.smartagri.tn/agriculture/get_data.php?sensor_soil`
        )
      ).data.data,
      {
        Temperature: "temperature",
        Humidity: "humidity",
        Conductivity: "conductivity",
      }
    ),
    ["temperature", "Humidity", "Conductivity"]
  )
