import React, { useState, useEffect } from "react";
import { Box, Card, Typography } from "@mui/material";
import { DateRangePicker } from "react-date-range"; // NEED  date-fns 3.6.0 CHECK COMPATIBILITY
import "react-date-range/dist/styles.css"; // Main CSS file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import dayjs, { Dayjs } from "dayjs";
import CardComponent from "../Card/CardComponent"
import TypographyComponent from "../i18n/TypographyComponent"

const DateTimeRangePicker = ({ onChangeCallback }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: dayjs().add(1, "hour").toDate(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  useEffect(() => {
    const start = dayjs(selectionRange.startDate);
    const end = dayjs(selectionRange.endDate);
    onChangeCallback(start, end);
  }, [selectionRange]);

  const formatReadableInterval = () => {
    const { startDate, endDate } = selectionRange;
    if (!startDate || !endDate) return "";
    const formattedStart = dayjs(startDate).format("dddd, MMMM D, YYYY h:mm A");
    const formattedEnd = dayjs(endDate).format("dddd, MMMM D, YYYY h:mm A");
    return `${formattedStart} to ${formattedEnd}`;
  };

  return (
    <CardComponent  variant="outlined" title="Select Date & Time Interval" withAccordion>
      <Box>
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          direction="horizontal"
        />

        {/* Show interval below the picker */}
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <TypographyComponent variant="subtitle1">Selected Interval:</TypographyComponent>
          <Typography variant="body1" color="textSecondary">
            {formatReadableInterval()}
          </Typography>
        </Box>
      </Box>
    </CardComponent>
  );
};

export default DateTimeRangePicker;
